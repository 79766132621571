import { Component, OnInit } from '@angular/core';
import {} from 'googlemaps'
import { environment } from 'src/environments/environment';
import { VersionCheckService } from './service/version-check.service';
import * as PackageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private versionCheckService: VersionCheckService,
  ) { }

  ngOnInit(){
    environment.VERSION = PackageJson.version
    this.versionCheckService.initVersionCheck(environment.versionCheckURL)
  }
}