import { Injectable } from '@angular/core';
import { Observable, Subject } from '../../../node_modules/rxjs';
import { Alert } from '../clases/alert';
import { Router, NavigationStart } from '../../../node_modules/@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject<Alert>();
  private keepAfterRouterChange = false;

  constructor(private router: Router) { 
    router.events.subscribe(event => {
      if(event instanceof NavigationStart){
        this.keepAfterRouterChange = false;
      }else{
        this.clear();
      }
    }); 
  }

  getAlert(): Observable<any>{
    return this.subject.asObservable();
  }

  success(title: string, message?: string){
    const alert = new Alert();
    alert.titleAlert = title;
    alert.messageAlert = message;
    alert.typeAlert = "success";
    this.alert(alert);
  }

  error(title: string, message?: string){
    const alert = new Alert();
    alert.titleAlert = title;
    alert.messageAlert = message;
    alert.typeAlert = "danger";
    this.alert(alert);
  }

  warning(title: string, message?: string){

    const alert = new Alert();
    alert.titleAlert = title;
    alert.messageAlert = message;
    alert.typeAlert = "warning";
    this.alert(alert);
  }

  secondary(title: string, message?: string){
    const alert = new Alert();
    alert.titleAlert = title;
    alert.messageAlert = message;
    alert.typeAlert = "secondary";
    this.alert(alert);
  }


  alert(alert: Alert){

    this.keepAfterRouterChange = this.keepAfterRouterChange;
    this.subject.next(alert);
  }

  clear(){
    this.subject.next();
  }
}
