import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FacebookModule } from 'ngx-facebook';

import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { AppRoutes } from './app.routes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UsuarioResolve } from './usuario-resolve';
import { LoginResolve } from './resolves/login-resolve';
import { UsuariosResolve } from './resolves/usuario-resolve';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';

defineLocale('es', esLocale);

import { DatePipe } from '@angular/common';

import { UserIdleModule } from 'angular-user-idle';
import { environment } from 'src/environments/environment';

import { DefaultModule } from './default/default/default.module';
import { RestriccionComponent } from './develop/restriccion/restriccion.component';
import { sharedImputDirectiveModule } from './util/sharedInput.module';
import { AuthInterceptorService } from './service/auth-interceptor.service';



@NgModule({
  declarations: [
    AppComponent,
    RestriccionComponent,

  ],
  imports: [
    DefaultModule,
    BrowserModule,
    AppRoutes,
    HttpClientModule,
    AlertModule.forRoot(),
    BrowserAnimationsModule,
    FacebookModule.forRoot(),
    ButtonsModule.forRoot(),
    UserIdleModule.forRoot({ idle: environment.TIEMPO_SESION.TIEMPO, timeout: environment.TIEMPO_SESION.TIMEOUT, ping: environment.TIEMPO_SESION.PING }),
    sharedImputDirectiveModule
  ],
  exports: [
  ],
  providers: [UsuarioResolve, LoginResolve, UsuariosResolve, DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
