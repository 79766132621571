import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as Cookie from '../util/cookies/cookies';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { MenuComponent } from '../components/menu/menu.component';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

  constructor(    
    private router: Router,
    private alertService: AlertService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    const token: string = Cookie.getCookie(Cookie.tokenCookieName);

    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ Cookie.getCookie(Cookie.tokenCookieName) }`
        }
      });
    }
    
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.status === 401) {
          this.returnLoginInteceptError()
          this.alertService.warning('Su sesión ha expirado, por favor inicie sesión nuevamente.');
        }

        if (err.status === 403) {
          this.returnLoginInteceptError()
          this.alertService.warning('Su sesión ha expirado, por favor inicie sesión nuevamente.');
        }

        return throwError( err );

      })
    );
  }

  returnLoginInteceptError(){
    Cookie.removeCookie(Cookie.tokenCookieName);
    localStorage.removeItem('currentUser' + '_' + environment.NOMBRE_EMPRESA);
    localStorage.removeItem('country');
    this.router.navigate(['/']);
  }

}
