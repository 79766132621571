import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './default.component';
import { AuthGuard } from 'src/app/guard/auth.guard';
import { UsuarioResolve } from 'src/app/usuario-resolve';

const routes: Routes = [{
  path: 'default', 
  component: DefaultComponent, 
  canActivateChild: [AuthGuard],
  resolve: {
    default: UsuarioResolve
  },
  children: [
    {
      path: '', redirectTo: 'solicitar-servicio', pathMatch: 'full'
    },
    // {
    //   path: 'solicitar-servicio', loadChildren: () => import('../../components/solicitar-servicio-multiple/solicitar-servicio-multiple.module').then(m => m.SolicitarServicioMultipleModule)
    // },
    {
      path: 'solicitar-servicio', loadChildren: () => import('../../components/solicitar-servicio/solicitar-servicio.module').then(m => m.SolicitarServicioModule)
    },
    {
      path: 'envios', loadChildren: () => import('../../components/envios/envios.module').then(m => m.EnviosModule)
    },
    {
      path: 'cuenta', loadChildren: () => import('../../components/cuenta/cuenta.module').then(m => m.CuentaModule)
    },
    {
      path: 'usuarios', loadChildren: () => import('../../components/usuario/usuario.module').then(m => m.UsuarioModule)
    },
    {
      path: 'miEmpresa', loadChildren: () => import('../../components/empresa/empresa.module').then(m => m.EmpresaModule)
    },
    {
      path: 'empresas', loadChildren: () => import('../../components/empresas/empresas.module').then(m => m.EmpresasModule)
    },
    {
      path: 'centro-costo', loadChildren: () => import('../../components/centro-costo/centro-costo.module').then(m => m.CentroCostoModule)
    },
    {
      path: 'reporte-empresarial', loadChildren: () => import('../../components/reporte-empresarial/reporte-empresarial.module').then(m => m.ReporteEmpresarialModule)
    },
    {
      path: 'sedes', loadChildren: () => import('../../components/sedes/sedes.module').then(m => m.SedesModule)
    },
    {
      path: 'reporte-aprobacion', loadChildren: () => import('../../components/reporte-aprobacion/reporte-aprobacion.module').then(m => m.ReporteAprobacionModule)
    },
    {
      path: 'zona-reserva', loadChildren: () => import('../../components/zona-reserva/zona-reserva.module').then(m => m.ZonaReservaModule)
    },
    {
      path: 'configuracion-parametros', loadChildren: () => import('../../components/config-parametros/config-parametros.module').then(m => m.ConfigParametrosModule)
    },
    {
      path: 'metodo-pago', loadChildren: () => import('../../components/metodo-pago/metodo-pago.module').then(m => m.MetodoPagoModule)
    },
    {
      path: 'codigo-promocional', loadChildren: () => import('../../components/codigo-promocional/codigo-promocional.module').then(m => m.CodigoPromocionalModule)
    },
    {
      path: 'monitoreo', loadChildren: () => import('../../components/monitoreo/monitoreo.module').then(m => m.MonitoreoModule)
    },
    {
      path: 'tracking-servicio', loadChildren:() => import('../../components/tracking-servicio/tracking-servicio.module').then(m => m.TrackingServicioModule)
    },
    {
      path: 'tracking-servicio/:id/:idEmpre', loadChildren:() => import('../../components/tracking-servicio/tracking-servicio.module').then(m => m.TrackingServicioModule)
    },
    {
      path: 'otros-reportes', loadChildren:() => import('../../components/otros-reportes/otros-reportes.module').then(m => m.OtrosReportesModule)
    },
    {
      path: 'area', 
      loadChildren: () => import('../../components/area/area.module' ).then(m => m.AreaModule)
    }
  ]
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DefaultRoutingModule { }
