import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { UserService } from './user.service';

import { Router } from '@angular/router';
import { CuentaService } from './cuenta.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { environment } from '../../environments/environment';
import { DatosFb } from '../clases/datos-fb';
import { Campos } from '../clases/campos';
import { RequestServicio } from '../clases/request-servicio';
import { ConfiguracionSolicitar } from '../clases/configuracionSolicitar';
import * as Cookies from '../util/cookies/cookies';
import { Multidestino } from '../clases/multidestino';
import  * as NavidateUserAgent from '../util/detect-navigator/navigator-user-agent';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class DatosCompartidosService {


  public _user = new BehaviorSubject<Array<any>>([]);
  user$ = this._user.asObservable();

  public _solicitarServicio = new BehaviorSubject<RequestServicio>(new RequestServicio());
  solicitarServicio$ = this._solicitarServicio.asObservable();

  public _configuracionSolicitar = new BehaviorSubject<ConfiguracionSolicitar>(new ConfiguracionSolicitar());
  configuracionSolicitar$ = this._configuracionSolicitar.asObservable();

  //public _datosFb = new BehaviorSubject<DatosFb>()
  public datosFb$: BehaviorSubject<Array<DatosFb>> = new BehaviorSubject<Array<DatosFb>>([]);

  constructor(
    private userService: UserService,
    private router: Router,
    private cuentaService: CuentaService,
    private alertService: AlertService,
    private _http: HttpClient
  ) {
  }

  guardarUsuario(user: any): Observable<number> {
    let error: any;
    return this.userService.requestLogin(user).pipe(map((data: any) => {
      //console.log(data);

      if (data.idResultado == 1) {
        // var cliente: Usuario = data.cliente as Usuario
        if (data.cliente.menu.length > 0) {
          const currentUser = {
            IdCliente: data.cliente.IdCliente,
            Rol: data.cliente.Rol
          }
          // currentUser.Rol = 1;
          this.loginCliente(data.cliente, currentUser)
        } else {
          error = environment.MENSAJES.LOGIN.CORREO_SIN_ACCESOS.CODIGO;
        }

      } else if (data.idResultado == environment.MENSAJES.LOGIN.USUARIO_INACTIVO.CODIGO) {
        //Clave incorrecta
        //this.alertService.error('', environment.MENSAJES.LOGIN.USUARIO_INACTIVO.DESCRIPCION);
        error = environment.MENSAJES.LOGIN.USUARIO_INACTIVO.CODIGO;
      } else if (data.idResultado == environment.MENSAJES.LOGIN.CLAVE_INCORRECTA.CODIGO) {

        // this.alertService.error('', environment.MENSAJES.LOGIN.CLAVE_INCORRECTA.DESCRIPCION);
        error = environment.MENSAJES.LOGIN.CLAVE_INCORRECTA.CODIGO;

      } else if (data.idResultado == environment.MENSAJES.LOGIN.NO_EXISTE_USUARIO.CODIGO) {
        // this.alertService.error('', environment.MENSAJES.LOGIN.NO_EXISTE_USUARIO.DESCRIPCION);
        error = environment.MENSAJES.LOGIN.NO_EXISTE_USUARIO.CODIGO;

      } else if (data.idResultado == environment.MENSAJES.LOGIN.ERROR.CODIGO) {
        // this.alertService.error('', environment.MENSAJES.LOGIN.ERROR.DESCRIPCION);
        error = environment.MENSAJES.LOGIN.ERROR.CODIGO;

      } else if (data.idResultado == environment.MENSAJES.LOGIN.CORREO_DUPLICADO.CODIGO) {
        error = environment.MENSAJES.LOGIN.CORREO_DUPLICADO.DESCRIPCION;
      }
      return error;
    }));

  }

  iniciarSesionFB(email: string): Observable<any> {
    let resp: any = 2;
    let json = {
      email: email,
      idEquipo:NavidateUserAgent.ipConfig,
      versionSO:NavidateUserAgent.getSOVersion(),
      versionPlataforma:NavidateUserAgent.getNavigatiorVersion(),
      plataforma: "Web " + environment.VERSION
    }
    //let usuario;


    return this.userService.validarCorreo(json).pipe(map((data: any) => {
      //  console.log(data);
      if (data.idResultado == 1) {
        const currentUser = {
          IdCliente: data.IdCliente,
          Rol: data.Rol
        }
        // Cookies.cookieTokenAdd(data.idToken);
        this.loginCliente(data, currentUser)
        // this._user.next(data);
        // localStorage.setItem('currentUser' + '_' + environment.NOMBRE_EMPRESA, JSON.stringify(currentUser));
        
        // this.resetMarkers()
        // this.router.navigate(['/default']);


      } else if (data.idResultado == -1) {

        this.alertService.error('Error');
        resp = -1;

      } else if (data.idResultado == -2) {

        resp = -2;
      }
      return resp;
    }));



  }

  obtenerUsuario(idUsuario: any): Observable<any> {
    let usuario;

    return this.cuentaService.getUsuario(idUsuario).pipe(map((data: any) => {
      usuario = data;
      // console.log('usuariooooooooooooo')
      // console.log(usuario);

      this._user.next(usuario);
      if (usuario.prefijo === null || usuario.prefijo === undefined || usuario.prefijo === '') {
        usuario.prefijo = '+51';
      } else {
        usuario.prefijo = '+' + usuario.prefijo;
      }

      if (usuario.isoCountryCode === undefined || usuario.isoCountryCode === "" || usuario.isoCountryCode === null) {
        usuario.isoCountryCode = 'PE';
      }

      if (usuario.camposDinamicos !== undefined && usuario.camposDinamicos !== null) {
        let camposDinamicos = usuario.camposDinamicos;

        camposDinamicos.forEach(element => {
          let campo: Campos = new Campos();
          if (element.c === 'COMBO' && element.i.length !== undefined) {
            element.i.splice(0, 0, '--SELECCIONE--')
            campo.t = element.i[0];
          }
        });

      }

      return usuario;

    }));


  }

  getDatosFB() {
    return this.datosFb$;
  }

  getDatosRegistroFB() {
    return this.getDatosFB().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateSolicitar(solicitar: RequestServicio) {
    this._solicitarServicio.next(solicitar)
  }

  updateConfiguracionSolicitar(configuracion: ConfiguracionSolicitar) {
    this._configuracionSolicitar.next(configuracion)
  }

  loginCliente(cliente: any, currentUser: any) {
    localStorage.setItem('currentUser' + '_' + environment.NOMBRE_EMPRESA, JSON.stringify(currentUser));
    this._user.next(cliente);
    Cookies.cookieTokenAdd(cliente.idToken);
    this.resetMarkers()
    this.router.navigate(['/default']);
  }

  resetMarkers() {
    console.log(this._solicitarServicio)
    let listaLimpia: Multidestino[] = [];
    let index = 0
    this._solicitarServicio.value.lstMarkerDestino.forEach(element => {
      if (element.marker && element.marker.getMap()) {
        element.marker.setMap(null);
      }
      if (element.marker && element.marker.getPosition()) {
        element.marker.setPosition(null);
      }
    });

    if (this._solicitarServicio.value.lstMarkerDestino.length > 2) {
      this._solicitarServicio.value.lstMarkerDestino.splice(2, this._solicitarServicio.value.lstMarkerDestino.length - 1);
    }
  }


}
