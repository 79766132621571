import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UsuarioService } from "../service/usuario.service";
import { take, map, first } from "rxjs/operators";
import { Usuario } from "../clases/usuario";
@Injectable()
export class UsuariosResolve implements Resolve<Usuario> {

    constructor(
        private router: Router,
        private usuarioService: UsuarioService) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<Usuario> {

        const id = route.paramMap.get('id');
        return this.usuarioService.getUsuario(id).pipe(
            take(1),
            map( (usuario) => {
                if(usuario){
                    return usuario;
                }else{
                    this.router.navigate(['/default/usuarios']);
                    return null;
                }
            })
        );
    }
}
