import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { DatosCompartidosService } from "./service/datos-compartidos.service";
import { Observable } from "rxjs";
import { Nexus } from "./util/nexus";
import { SolicitarServicioService } from "./service/solicitar-servicio.service";
import { environment } from "../environments/environment";

@Injectable()
export class UsuarioResolve implements Resolve<any> {

    constructor(
        private datosCompartidosService: DatosCompartidosService,
        private router: Router,
        private solicitarServicioService: SolicitarServicioService
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<any> {

        const user = JSON.parse(localStorage.getItem('currentUser'+'_'+environment.NOMBRE_EMPRESA));
        if (user != undefined) {
            //console.log('usuario resolve')
            //Country 
            Nexus.geolocate();
            Nexus.getCountryClient(this.solicitarServicioService);
            return this.datosCompartidosService.obtenerUsuario(user.IdCliente);


        } else {
            this.router.navigate(['']);
        }

    }

}
