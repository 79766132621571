import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'colorEstado'
})
export class ColorEstadoPipe implements PipeTransform {

  transform(idConductor: string, idEstado:number): any {
    this.createClassStyle("M-" + idConductor, idEstado);
  }

  createClassStyle(selector: string, idEstado: number) {
    let cssStyle = '';
    let estado;

    switch (idEstado) {
      case environment.ESTADO.LIBRE:
        estado = '#27ae60';
        break;
      case environment.ESTADO.LEIDO:
        estado = '#ff5252';
        break;
      case environment.ESTADO.ASIGNADO:
        estado = '#FF9800';
        break;
      case environment.ESTADO.LLEGO_DESTINO:
        estado = '#ef9a9a';
        break;
      case environment.ESTADO.PENDIENTE:
        estado = '#26a69a'; 
        break;
      case environment.ESTADO.INICIO:
        estado = '#3D5afe';
        break;
      case environment.ESTADO.CONTACTO:
        estado = '#5c6bc0';
        break;
      case environment.ESTADO.RECHAZADO:
        estado = '#d50000';
        break;
      case environment.ESTADO.OCUPADO:
        estado = '#4c535a';
        break;
      case environment.ESTADO.FIN_JORNADA:
        estado = '#21252921';
        break;
      case environment.ESTADO.ANULADO1:
        estado = '#adb5df';
        break;
      case environment.ESTADO.ANULADO2:
        estado = '#adb5df';
        break;
      case environment.ESTADO.ANULADO3:
        estado = '#adb5df';
        break;
    }

    cssStyle = '{border: 1px solid' + estado + '; background: ' + estado + ';}'

    this.createCSSSelector(selector, cssStyle)
  }

  createCSSSelector(selector, cssStyle) {
    var style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = '.' + selector + cssStyle;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

}
