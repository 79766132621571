import { environment } from "src/environments/environment";
import { ConfiguracionSolicitar, tiposVechiculos } from "src/app/clases/configuracionSolicitar";
import { RequestServicio } from "src/app/clases/request-servicio";
import { Multidestino } from "src/app/clases/multidestino";
import { Tarifa } from "src/app/clases/tarifa";
import { Usuario } from "src/app/clases/usuario";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export const PLACEHOLDER_CONFIGURACION = {
    PHL_RUC: environment.DOCUMENTO_FISCAL.NOMBRE
}

export function initSolicitar(solicitar: RequestServicio, user: Usuario, configuracionSolicitar: ConfiguracionSolicitar): RequestServicio {
    solicitar.I007_ModoReserva = environment.MODO_RESERVA.WEB;
    solicitar.Multidestino = environment.MULTIDESTINO;
    solicitar.Creacion_Usuario = user.Email;
    solicitar.modificoUsuario = user.Email;
    solicitar.idEmpresa = user.IdEmpresa;
    solicitar.idCliente = user.IdCliente;
    solicitar.idCentroCosto = (configuracionSolicitar.centroCostoEditable) ? null : user.IdCentroCosto;
    solicitar.datosUsuario.centroCosto = (configuracionSolicitar.centroCostoEditable) ? null : (user.IdCentroCosto + '');
    solicitar.datosUsuario.centroCosto = (configuracionSolicitar.centroCostoEditable) ? null : (user.IdCentroCosto + '');
    solicitar.I040_Visita = (!solicitar.I040_Visita) ? 0 : solicitar.I040_Visita;
    solicitar.lstMarkerDestino = solicitar.lstMarkerDestino

    let index: number = 0
    solicitar.lstMarkerDestino.forEach(element => {
        if(element.marker){
            element.marker.setDraggable(true);
        }
        if(<HTMLInputElement>document.getElementById('txtDestino_' + index)){
            let input = <HTMLInputElement>document.getElementById('txtDestino_' + index);
            input.value = (element.direccion) ? element.direccion : '';
            index++;

        }
    });
    
    return solicitar;
}

export function simpleStringify(object) {
    var simpleObject = {};
    for (var prop in object) {
        if (!object.hasOwnProperty(prop)) {
            continue;
        }
        if (typeof (object[prop]) == 'object') {
            continue;
        }
        if (typeof (object[prop]) == 'function') {
            continue;
        }
        simpleObject[prop] = object[prop];
    }
    return simpleObject; // returns cleaned up JSON
}

export function TarifaPrimerDestino(index: number, isLastIndex: boolean, configuracion: ConfiguracionSolicitar, solicitar: RequestServicio): boolean {
    if (index == 0) {
        return true;
    }
    if (solicitar.Retorno && configuracion.retornoUltimoDestino && isLastIndex) {
        return true;
    }
    return false;
}

export function DateSetUTC(dias: Date, horas: Date): Date {
    return new Date(Date.UTC(dias.getFullYear(), dias.getMonth(), dias.getDate(),
        horas.getHours(), horas.getMinutes(), 0, 0))
}

export function DateDeleteUTC(dias: Date, horas: Date): Date {
    return new Date(dias.getFullYear(), dias.getMonth(), dias.getDate(),
        horas.getHours(), horas.getMinutes(), 0, 0)
}

export function GetDateUTC(date: Date, horas: Date) : Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(),
        horas.getHours(), horas.getMinutes() + horas.getTimezoneOffset(), 0, 0)
}

export function dtFechaDateStringTimeDateTime(date, myTime: Date): string {
    let hour = '' + myTime.getHours();
    if (myTime.getHours() < 10) {
        hour = '0' + hour;
    }

    let minute = '' + myTime.getMinutes();
    if (myTime.getMinutes() < 10) {
        minute = '0' + minute;
    }
    const seconds = '00';

    const fechaServicio = date + ' ' + hour + ':' + minute + ':' + seconds;
    return fechaServicio;
}

export function dtFechaServicioString(myDate: Date, myTime: Date): string {
    let day = '' + myDate.getDate();
    if (myDate.getDate() < 10) {
        day = '0' + day;
    }
    const mes = (myDate.getMonth() + 1)
    let month = '' + mes;
    if (myDate.getMonth() + 1 < 10) {
        month = '0' + month;
    }

    const year = myDate.getFullYear();

    let hour = '' + myTime.getHours();
    if (myTime.getHours() < 10) {
        hour = '0' + hour;
    }

    let minute = '' + myTime.getMinutes();
    if (myTime.getMinutes() < 10) {
        minute = '0' + minute;
    }

    const seconds = '00';

    // yyyy-mm-ddThh:mi:ss.mmm
    const fechaServicio = day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + seconds;
    return fechaServicio;
}

export function dtFechaServicioMilitar(myDate: Date, myTime: Date): string {
    let day = '' + myDate.getDate();
    if (myDate.getDate() < 10) {
        day = '0' + day;
    }
    const mes = (myDate.getMonth() + 1)
    let month = '' + mes;
    if (myDate.getMonth() + 1 < 10) {
        month = '0' + month;
    }

    const year = myDate.getFullYear();

    let hour = '' + myTime.getHours();
    if (myTime.getHours() < 10) {
        hour = '0' + hour;
    }

    let minute = '' + myTime.getMinutes();
    if (myTime.getMinutes() < 10) {
        minute = '0' + minute;
    }
    const seconds = '00';

    const fechaServicio = year + month + day + ' ' + hour + ':' + minute + ':' + seconds;
    return fechaServicio;
}

export function dtFechaServicio(myDate: Date, myTime: Date): string {
    let day = '' + myDate.getDate();
    if (myDate.getDate() < 10) {
        day = '0' + day;
    }
    const mes = (myDate.getMonth() + 1)
    let month = '' + mes;
    if (myDate.getMonth() + 1 < 10) {
        month = '0' + month;
    }

    const year = myDate.getFullYear();

    let hour = '' + myTime.getHours();
    if (myTime.getHours() < 10) {
        hour = '0' + hour;
    }

    let minute = '' + myTime.getMinutes();
    if (myTime.getMinutes() < 10) {
        minute = '0' + minute;
    }
    const seconds = '00';

    const fechaServicio = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds;
    return fechaServicio;
}

export function NgbDateFechaMilitar(date: NgbDateStruct, myTime: Date): string {
    let day = '' + date.day;
    if (date.day < 10) {
        day = '0' + date.day;
    }

    let month = '' + date.month;
    if (date.month < 10) {
        month = '0' + date.month;
    }

    let hour = '' + myTime.getHours();
    if (myTime.getHours() < 10) {
        hour = '0' + hour;
    }

    let minute = '' + myTime.getMinutes();
    if (myTime.getMinutes() < 10) {
        minute = '0' + minute;
    }

    const fechaServicio = date.year + month + day + ' ' + hour + ':' + minute +':00';
    return fechaServicio;
}

export function NgbDateFecha(date: NgbDateStruct, myTime: Date): string {
    let day = '' + date.day;
    if (date.day < 10) {
        day = '0' + date.day;
    }

    let month = '' + date.month;
    if (date.month < 10) {
        month = '0' + date.month;
    }

    let hour = '' + myTime.getHours();
    if (myTime.getHours() < 10) {
        hour = '0' + hour;
    }

    let minute = '' + myTime.getMinutes();
    if (myTime.getMinutes() < 10) {
        minute = '0' + minute;
    }
    const fechaServicio = date.year + '-' + month + '-' + day;
    return fechaServicio;
}

export function NgbDateFechaString(date: NgbDateStruct, myTime: Date): string {
    let day = '' + date.day;
    if (date.day < 10) {
        day = '0' + date.day;
    }

    let month = '' + date.month;
    if (date.month < 10) {
        month = '0' + date.month;
    }

    let hour = '' + myTime.getHours();
    if (myTime.getHours() < 10) {
        hour = '0' + hour;
    }

    let minute = '' + myTime.getMinutes();
    if (myTime.getMinutes() < 10) {
        minute = '0' + minute;
    }

    const fechaServicio = day + '/' + month + '/' + date.year;
    return fechaServicio;
}

export function saveKilometraje(lstTarifa: any[], solicitar: RequestServicio): Multidestino[] {
    lstTarifa.forEach(tarifa => {
        solicitar.lstMarkerDestino.forEach(destino => {
            const kilometraje = tarifa.distancia.split(' ');
            destino.kilometros = parseFloat(kilometraje[0])
        });
    });
    return solicitar.lstMarkerDestino;
}

export function ReturnJsonConsultarTarifa(listaLimpia: Multidestino[], solicitar: RequestServicio): any {
    // let listaLimpiaLength = listaLimpia.length - 1;

    const tarifaMultiDestino: Tarifa[] = [];
    for (let i = 0; i < listaLimpia.length - 1; i++) {
        let tarifa = new Tarifa();
        tarifa.primerDestino = TarifaPrimerDestino(i, ((listaLimpia.length - 1 - 1 == i) ? true : false), this.configuracionSolicitar, solicitar);
        tarifa.origenLatitud = listaLimpia[i].marker.getPosition().lat();
        tarifa.origenLongitud = listaLimpia[i].marker.getPosition().lng();
        tarifa.destinoLatitud = listaLimpia[i + 1].marker.getPosition().lat();
        tarifa.destinoLongitud = listaLimpia[i + 1].marker.getPosition().lng();
        tarifa.dirDestino = listaLimpia[i + 1].direccion;
        tarifa.dirOrigen = listaLimpia[i].direccion;
        tarifa.idEmpresa = solicitar.idEmpresa;
        tarifa.idTipoPago = solicitar.I008_TipoPago;
        tarifa.tipoServicio = solicitar.I011_TipoServicio;
        tarifa.modoReserva = environment.MODO_RESERVA.WEB
        tarifaMultiDestino.push(tarifa);
    }
    // if (this.idPromocion == 'undefined' && this.idPromocion == null || this.idPromocion == '') {
    //   tarifa.idPromoActivacion = '0'
    // } else {
    //   tarifa.idPromoActivacion = this.idPromocion
    // }

    // let fecha = '';
    // if (this.flagServicioRutina) {
    //     fecha = dtFechaDateStringTimeDateTime(this.lstServicioRutina[indexServicioRutina].fechaString, solicitar.varHoraServicio);
    // } else {
    //     fecha = dtFechaServicioString(solicitar.varFechaServicio,solicitar.varHoraServicio);
    // }
    return {
        idCliente: solicitar.idCliente,
        idPromoActivacion: solicitar.idPromoActivacion,
        dtfechaServicio: (solicitar.anticipada) ?
            dtFechaServicioString(solicitar.varFechaServicio, GetDateUTC(solicitar.varFechaServicio, solicitar.varHoraServicio))
            : dtFechaServicioString(new Date(), new Date()),
        anticipada: solicitar.anticipada,
        lstDestinos: tarifaMultiDestino
    };
}

export function configuracionAeropuertoOpcionNacionalInter(origenAeropuerto: boolean): boolean {
    if (environment.VALIDACIONES_SOLICITAR.AEROPUERTO_COMBO_NACIONAL_INTER && origenAeropuerto) {
        return true
    } else {
        return false
    }
}

export function configuracionAeropuertoDetalle(origenAeropuerto: boolean, user: Usuario): boolean {
    if (environment.VALIDACIONES_SOLICITAR.AEROPUERTO_DETALLES_VUELO && origenAeropuerto) {
        return true;
    } else {
        return false
    }
}

export function cambioHoraListaRutina(solicitar: RequestServicio): boolean {
    if (solicitar.listaRutina && solicitar.listaRutina.length > 0) {
        solicitar.listaRutina.forEach(element => {
            if (element.varHora.getHours() != this.solicitar.varHoraServicio.getHours() || element.varHora.getMinutes() != this.solicitar.varHoraServicio.getMinutes()) {
                return true
            }
        });
    }
    return false
}

export function actualizarConfiguracionUsuario(user: Usuario): ConfiguracionSolicitar {
    let configuracionSolicitar = new ConfiguracionSolicitar()

    if (environment.VALIDACIONES_SOLICITAR.RETORNO_ULTIMO_DESTINO && user.ServicioRetorno) {
        configuracionSolicitar.retornoHorario = false;
        configuracionSolicitar.retornoUltimoDestino = true;
    } else if (user.ServicioRetorno) {
        configuracionSolicitar.retornoHorario = true;
        configuracionSolicitar.retornoUltimoDestino = false;
    }

    configuracionSolicitar.instruccionDestino = environment.VALIDACIONES_SOLICITAR.INSTRUCCION_DESTINO;
    configuracionSolicitar.referenciaDestino = environment.VALIDACIONES_SOLICITAR.REFERENCIA_DESTINO;
    configuracionSolicitar.truncarTotalServicio = environment.VALIDACIONES_SOLICITAR.TRUNCAR_TOTAL_SERVICIO;
    configuracionSolicitar.seleccionarTipoPagoDefecto = environment.VALIDACIONES_SOLICITAR.SELECCIONAR_TIPO_PAGO_DEFECTO;
    configuracionSolicitar.contactoAdicional = environment.VALIDACIONES_SOLICITAR.CONTACTO_ADICIONAL;

    configuracionSolicitar.celularContacto = environment.VALIDACIONES_SOLICITAR.CELULAR_CONTACTO;
    configuracionSolicitar.observacion = environment.VALIDACIONES_SOLICITAR.OBSERVACION;

    if (environment.VALIDACIONES_SOLICITAR.TIPO_VEHICULO == tiposVechiculos.AUTO) {
        configuracionSolicitar.tipoVehiculo = tiposVechiculos.AUTO;
    } else if (environment.VALIDACIONES_SOLICITAR.TIPO_VEHICULO == tiposVechiculos.MOTO) {
        configuracionSolicitar.tipoVehiculo = tiposVechiculos.MOTO;
    } else {
        configuracionSolicitar.tipoVehiculo = tiposVechiculos.AUTO;
    }

    configuracionSolicitar.centroCostoEditable = (user.EditCC == 1) ? true : false;

    if (user.listaClienteEncargo != undefined && user.listaClienteEncargo != null && user.listaClienteEncargo.length > 0) {
        configuracionSolicitar.clienteCargo = true;
    }
    if (user.pedirTercero) {
        configuracionSolicitar.peditParaOtros = true
        if (user.Rol === environment.ROL.PARTICULAR) {
            if (user.pedirTercero) {
                configuracionSolicitar.pedirOtrosPersonal = false;
                configuracionSolicitar.pedirOtrosVisita = true;
            }
        }
        else {
            configuracionSolicitar.pedirOtrosPersonal = user.pedirPersonal;
            configuracionSolicitar.pedirOtrosVisita = user.pedirVisita;
        }
    }
    // Sertvicio Rutina
    configuracionSolicitar.servicioTipoRutina = environment.VALIDACIONES_SOLICITAR.SOLICITAR_SERVICIO_RUTINA;
    configuracionSolicitar.listaCodigoPromocional = environment.VALIDACIONES_SOLICITAR.LISTAR_CODIGO_PROMOCIONAL;
    configuracionSolicitar.modalValidacionServicioASolicitar = environment.VALIDACIONES_SOLICITAR.MODAL_VERIFICACION_DATOS_SERVICIO;

    configuracionSolicitar.cobrarUltimoDestinoComoPrimerDestino = environment.VALIDACIONES_SOLICITAR.ULTIMO_DESTINO_TARIFAR_COMO_PRIMER_DESTINO;

    return configuracionSolicitar
}