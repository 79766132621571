import { Destino } from "./destino";

export class Tarifa {
  idCliente:number;
  idPromoActivacion: string;
  destinoLatitud: number;
  destinoLongitud: number;
  dirDestino: string;
  dirOrigen: string;
  idEmpresa: number;
  idTipoPago: number;
  origenLatitud: number;
  origenLongitud: number;
  tipoServicio: number;
  lstDestinos: Destino[];
  primerDestino: boolean;
  dtfechaServicio: string;
  modoReserva: number;
  constructor() {

  }
}

export class TarifaMultiDestinoResponse {
  monto: number;
  distancia: number;
  id: string;
  constructor() {

  }
}

export class TarifaMultiDestino {
  latLngOrigen: google.maps.LatLng;
  latLngDestino: google.maps.LatLng;
  id: string;
}
