export class Multidestino {
  idCliente: number;
  idPromoActivacion: string;
  pasajero: string;
  numeroContacto: string;
  kilometros: number;
  tarifa: number;
  Referencia:  string;
  txtShowMarker: boolean;
  marker: google.maps.Marker;
  
    index: number;
    direccion: string;
    direccionGeocode: string;
    latllng: google.maps.LatLng;
    estado: boolean;
    showReferencia: boolean;
    Instruccion:string;

    constructor() {
        this.txtShowMarker = false;
        this.showReferencia = false;
    }
}
