import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

import { HttpClient } from "@angular/common/http"
import { Usuario } from '../clases/usuario';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private usuario$: BehaviorSubject<Usuario[]> = new BehaviorSubject<Usuario[]>([]);

  constructor(private _http: HttpClient) { }

  obtenerCliente(id: number) { //idEmpresa: number, idCentroCosto: number, idUsuario: number
    const url = environment.URL_BASE + "clienteAuthorization/obtenerCliente?id=" + id;
    return this._http.get(url);
  }

  centroCosto(IdEmpresa: any) {
    const url = environment.URL_BASE + 'centrocostoAuthorization/getempresa' + "?id=" + IdEmpresa
    return this._http.get(url)
  }

  centroCostoSinAutetication(IdEmpresa: any) {
    const url = environment.URL_BASE + 'centrocosto/getempresa' + "?id=" + IdEmpresa
    return this._http.get(url)
  }

  empresa(id: number) {
    const url = environment.URL_BASE + 'empresaAuthorization/all' + "?id=" + id
    return this._http.get(url)
  }

  compania(id: number) {
    const url = environment.URL_BASE + 'compañiaAuthorization/all' + "?id=" + id
    return this._http.get(url)
  }

  perfil(idUsuario: number) {
    const url = environment.URL_BASE + 'rolAuthorization/all' + "?id=" + idUsuario
    return this._http.get(url)
  }


  listarUsuarios(obj: any) {
    const url = environment.URL_BASE + 'clienteAuthorization/filter';
    return this._http.post(url, obj).pipe(
      map((res: any) => {
        this.usuario$.next(res.data);
        return res;
      })
    )
  }

  getUsuarios() {
    //console.log(this.usuario$, ["GET USUARIOS SERVICE"]);

    return this.usuario$;
  }

  getUsuario(id: number | string) {
    //console.log("get Usuario");

    return this.getUsuarios().pipe(
      map(listaUsuarios => listaUsuarios.find(usuario => usuario.IdCliente === +id))
    );
  }

  GuardarUsuario(usuario: Usuario) {
    const url = environment.URL_BASE + 'clienteAuthorization/save'
    return this._http.post(url, usuario)
  }

  listarArea(IdEmpresa: any) {
    const url = environment.URL_BASE + 'clienteAuthorization/listarArea' + "?idEmpresa=" + IdEmpresa
    return this._http.get(url)
  }

  listarSupervisor(IdEmpresa: any, IdCentroCosto: any, busqueda: string) {
    const url = environment.URL_BASE + "clienteAuthorization/getSupervisoresAutocomplete?idEmpresa=" + IdEmpresa + "&idCentroCosto=" + IdCentroCosto
      + "&busqueda=" + busqueda
    return this._http.get(url)
  }

  saldo(idCliente) {
    const url = environment.URL_BASE + "presupuestoAuthorization/saldo?idCliente=" + idCliente;
    return this._http.get(url)
  }

  exportExcel(idEmpresa: number): Observable<Blob> {
    const url = environment.URL_REPORTE + 'reportes/usuarios?idEmpresa=' + idEmpresa;

    return this._http.get(url, { responseType: 'blob' }).pipe(
      map(((res: Blob) => new Blob(([res]), { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })

      )));
  }

  cambiarEstadoUsuarios(data: any) {
    const url = environment.URL_BASE + "clienteAuthorization/desactivarCliente";
    return this._http.post(url, data)
  }

  listarPresupuestoDisponible(id: number) {
    const url = environment.URL_BASE + 'presupuestoAuthorization/AvalibleClient' + "?id=" + id;
    return this._http.get(url)
  }

}
