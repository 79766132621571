import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DatosCompartidosService } from "../service/datos-compartidos.service";
import { environment } from "../../environments/environment";
@Injectable()
export class LoginResolve implements Resolve<any> {

    constructor(private datosCompartidosService: DatosCompartidosService,
        private router: Router) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<any> {
        //console.log('ResolveL');
        let user: any = JSON.parse(localStorage.getItem('currentUser'+'_'+environment.NOMBRE_EMPRESA));
        //console.log(user);
        if (user!= undefined) {
            this.router.navigate(['/default/']);
            return;
        }

    }
}
