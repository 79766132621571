import { Component, OnInit } from '@angular/core';
import { Alert } from '../../clases/alert';
import { AlertService } from '../../service/alert.service';
import { AlertAnimation } from '../../animations/animations-nexus';
import { debug } from 'util';

@Component({
  selector: 'nexus-alert',
  templateUrl: './nexus-alert.component.html',
  styleUrls: ['./nexus-alert.component.css'],
  animations: [AlertAnimation]
})
export class NexusAlertComponent implements OnInit {
  alerts: Alert[] = [];

  constructor(
    private alertService: AlertService
  ) {
    this.alerts = [];
  }


  ngOnInit(): void {
    this.alertService.getAlert().subscribe(
      (alertCurrent: Alert) => {

        if(!alertCurrent){
         
          this.alerts = [];
          return;
        }

        if (this.alerts.length > 0) {
        
          this.alerts[0].state = "inactive"
         
          setTimeout(()=>{
            this.alerts.shift();
          }, 500);
        }
        this.alerts.push(alertCurrent);
        //console.log(this.alerts.length);
      }
    );
  }
  cerrar(alert: Alert) {
    //console.log(alert);
    alert.state = alert.state === 'inactive' ? 'active' : 'inactive';
    setTimeout(()=>{
      this.alerts.pop();
    }, 500);
  }
}
