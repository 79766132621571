import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Tarifa } from '../clases/tarifa';
import { RequestServicio } from '../clases/request-servicio';
import { ResponseServicio } from '../clases/response-servicio';
import { CancelarServicio } from '../clases/cancelar-servicio';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SolicitarServicioService {

  constructor(private _http: HttpClient) {
  }

  requestDireccion(lat: any, lng: any) {
    const url = environment.URL_BASE + 'geocodeAuthorization/json?latlng=' + lat + ',' + lng;
    return this._http.get(url);
  }

  requestDireccionDefault(lat: any, lng: any) {
    const url = environment.URL_BASE + 'geocodeAuthorization/json?latlng=' + lat + ',' + lng;
    return this._http.get(url);
  }

  requestTarifa(json: any) {
    //console.log(json);
    const url = environment.URL_BASE + "tarifaAuthorization/consultar";
    return this._http.post(url, json);
  }

  requestCrearServicio(json: RequestServicio) {
    const url = environment.URL_BASE + "servicioAuthorization/multiSave";
    return this._http.post(url, json);
  }

  requestObtenerConductor(json: ResponseServicio) {
    const url = environment.URL_BASE + "servicioAuthorization/consultar";
    return this._http.post(url, json);
  }

  requestCancelarServicio(json: CancelarServicio) {
    const url = environment.URL_BASE + "servicioAuthorization/anular";
    return this._http.post(url, json);
  }

  requestTipoPago(IdCliente: any, isoCountryCode: string) {
    const url = environment.URL_BASE + "tipoPagoAuthorization/all?id=" + IdCliente + '&isoCountryCode=' + isoCountryCode;
    return this._http.get(url);
  }

  requestTipoPagoEmpresa(IdEmpresa) {
    const url = environment.URL_BASE + "tipoPagoAuthorization/all?idEmpresa=" + IdEmpresa;
    return this._http.get(url);
  }

  requestTipoPagoAll(IdCliente: any) {
    const url = environment.URL_BASE + "tipoPagoAuthorization/all?id=" + IdCliente;
    return this._http.get(url);
  }

  requestGeocerca(idEmpresa: any) {
    const url = environment.URL_BASE + "usuarioAuthorization/obtenergeocercas?idEmpresa=" + idEmpresa;
    return this._http.get(url);
  }

  requestTipoServicio(IdCliente: any) {
    const url = environment.URL_BASE + "tiposervicio/all?id="+ IdCliente;
    return this._http.get(url);
  }

  // consultarCodigo(IdCliente: any){
  //   const url = environment.URL_BASE + "promocion/wmObtenerPromocionCliente" +"?idCliente=" + IdCliente;
  //   return this._http.get(url);
  // }

  // requestCountry() {
  //   const url = environment.URL_GET_COUNTRY;
  //   return this._http.get(url).pipe(
  //     map((data: any) => {
  //       return data;
  //     })
  //   );
  // }

  requestCountryCode(latitud:number, longitud:number){
    const url = environment.URL_BASE + 'servicio/getCobertura?latitud=' +  latitud + '&longitud=' + longitud;
    return this._http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  requestLstPersonal(IdCliente: any) {
    const url = environment.URL_BASE + "cliente/listarPersonal?idCliente="+ IdCliente;
    return this._http.get(url);
  }

  requestLstPersonalAutocomplete(IdCliente: any, busqueda: string) {
    const url = environment.URL_BASE + "cliente/listarPersonalAutocomplete?idCliente="+ IdCliente+"&busqueda="+busqueda;
    return this._http.get(url);
  }


  conductoresCercanos(lat: any, lng: any) {
    const url = environment.URL_BASE + "tracking/consultaconductores?lat="+ lat +"&lng=" + lng;
    return this._http.get(url);
  }



  obtenerInformacionServicio(idServicio: number){
    const url = environment.URL_BASE + `/servicio/curso?idServicio=${idServicio}`;
    return this._http.get(url);
  }

}
