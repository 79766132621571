import { Injectable } from '@angular/core';
import { Observable, Subject } from '../../../node_modules/rxjs';
import { Modal } from '../clases/modal';
import { Router, NavigationStart } from '../../../node_modules/@angular/router';

@Injectable({
  providedIn: 'root'
})
export class modalService {
  private subject = new Subject<Modal>();
  private keepAfterRouterChange = false;

  modalSpinner:Modal = new Modal(); 
  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.keepAfterRouterChange = false;
      } else {
        this.clear();
      }
    });
  }
  getModal(): Observable<any> {
    return this.subject.asObservable();
  }

  cargar() {
    this.modalSpinner.state='active';
    this.modal(this.modalSpinner);
  }
  ocultarModal() {
    this.modalSpinner.state='inactive';
    this.modal(this.modalSpinner);
  }
  modal(modal: Modal) {
    this.keepAfterRouterChange = this.keepAfterRouterChange;
    this.subject.next(modal);
  }
  clear() {
    this.subject.next();
  }
}
