import { Directive, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appAutocompleteLoaded]'
})
export class AutocompleteLoadedDirective {

  private regex: RegExp = new RegExp(/^[0-9a-zA-Z]+$/);

  // Allow key codes for special events. Reflect :
  // tab, end, home
  private specialKeys: Array<string> = ['Tab', 'End', 'Home', 'ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Shift', 'Meta',
  'Control', 'ContextMenu', 'AltGraph', 'Escape', 'CapsLock', 'ScrollLock', 'Pause', 'Insert', 'Dead', 'NumLock', 'Alt'];

  @Input('appAutocompleteLoadedValue') value: string;
  @Output() autocompleteLoad: EventEmitter<any> = new EventEmitter();

  constructor(
    private el: ElementRef
    ) { }


  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    console.log(event)
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    
    let current: string = this.el.nativeElement.value;
    var concatenado: string;

    if(event.key){
      // console.log(event.key);       
      concatenado = this.validarValorActual(event, this.value);
      if(concatenado.length %3 == 0){
        this.autocompleteLoad.emit(concatenado);
      }
    }

  }

  validarValorActual(event, value: string): string{
    var del = event.keyCode;
    var letras: string = "";

      if(del == 8){
        return (value.slice(0,-1)).trim();
      }else{
        letras = event.key;
        return value.trim() + letras.trim();
      }
  }

}