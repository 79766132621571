import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../service/user.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute) { }



  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user: any = JSON.parse(localStorage.getItem('currentUser' + '_' + environment.NOMBRE_EMPRESA));
    if (user != undefined) {
      this.router.navigate(['/default']);
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.validarUsuario(state.url);
  }

  canLoad(route: Route) {
    const url = '/default/' + route.path;
    return this.validarUsuario(url);
  }

  validarUsuario(url: string): boolean {
    let user: any = JSON.parse(localStorage.getItem('currentUser' + '_' + environment.NOMBRE_EMPRESA));
    let band = false;
    if (user != undefined) {

      switch (user.Rol) {

        case environment.ROL.ADMIN:

          if (url === '/default' || url === '/default/empresa' || url === '/default/solicitar-servicio' || url === '/default/metodo-pago' || url === '/default/reporte-aprobacion' || url == 'default/configuracion-parametros') {
            this.router.navigate(['/default/usuarios']);
            band = false;
          } else {
            band = true;
          }
          break;
        case environment.ROL.ADMIN_CORPORATIVO:
           
          if (url == 'default/configuracion-parametros') {
            this.router.navigate(['/default/usuarios']);

            band = false;
          } else {
            band = true;
          }

          break;

        case environment.ROL.CORPORATIVO:
          //url === '/default/reporte-empresarial' ||
          if (url === '/default/usuarios' || url === '/default/reporte-aprobacion' || url === 'default/sedes' 
          || url == 'default/configuracion-parametros' || url == '/default/monitoreo') {
            this.router.navigate(['/default']);
            band = false;
          } else {
            band = true;
          }
          break;
        case environment.ROL.PARTICULAR:
          if (url === '/default/empresa' || url === '/default/usuarios' || url === '/default/reporte-empresarial' || url === '/default/reporte-aprobacion' 
          || url === 'default/sedes' || url == 'default/configuracion-parametros' || url == '/default/monitoreo') {
            this.router.navigate(['/default']);
            band = true;
          } else {
            band = true;
          }
          break;
        case environment.ROL.ASISTENTE_CORPORATIVO:
          if (url === '/default/empresa' || url === '/default/usuarios' || url === '/default/reporte-empresarial' || url === '/default/reporte-aprobacion' 
          || url === 'default/sedes' || url == 'default/configuracion-parametros' || url == '/default/monitoreo') {
            this.router.navigate(['/default']);
            band = false;
          } else {
            band = true;
          }

          break;
        case environment.ROL.SUPERVISOR:
          if (url === '/default/usuarios' || url === '/default/centro-costo' || url === 'default/sedes' || url == 'default/configuracion-parametros'
          || url == '/default/monitoreo') {
            this.router.navigate(['/default']);
            band = false;
          } else {
            band = true;
          }
          break;
        default:
          // this.router.navigate(['/default']);
          this.router.navigate(['/login']);
          band = true;
          break;

        case environment.ROL.OWNER:
          if (url.indexOf('configuracion-parametros') == -1) {
            this.router.navigate(['/default/configuracion-parametros']);
            band = false;
          } else {
            band = true;
          }
          break;

      }
    } else {
      this.router.navigate(['/']);
    }
    return band;
  }
}
