import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';

//Ngx Bootstrap
import { PopoverModule } from "ngx-bootstrap/popover";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { IconsModule } from 'src/app/icons/icons.module';
import { RouterModule } from '@angular/router';
import { MenuRoutingModule } from './menu-routing.module';

const NgxBootstrapModule = [
  PopoverModule.forRoot(),
  ModalModule.forRoot(),
  TooltipModule.forRoot()
]

@NgModule({
  imports: [
    CommonModule,
    NgxBootstrapModule,
    IconsModule,
    RouterModule,
  ],
  // declarations: [
  //   MenuComponent
  // ],
  // exports: [
  //   MenuComponent
  // ]
})
export class MenuModule { }
