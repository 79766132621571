import { trigger, state, style, transition, group, animate } from "@angular/animations";

export const SlideInOutAnimation = [
    trigger('slideInOut', [
        state('in', style({
            'max-height': '550px', 'opacity': '1', 'visibility': 'visible'
        })),
        state('out', style({
            'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
        })),
        transition('in => out', [group([
            animate('400ms ease-in-out', style({
                'opacity': '0'
            })),
            animate('600ms ease-in-out', style({
                'max-height': '0px'
            })),
            animate('700ms ease-in-out', style({
                'visibility': 'hidden'
            }))

        ])]),
        transition('out => in', [group([
            animate('1ms ease-in-out', style({
                'visibility': 'visible'
            })),
            animate('600ms ease-in-out', style({
                'max-height': '550px'
            })),
            animate('800ms ease-in-out', style({
                'opacity': '1'
            }))
        ]
        )])
    ])
];

export const AccordionAnimation = [
    trigger('accordionAnimation', [
        state('in', style({
            'max-height': '800px', 'opacity': '1', 'visibility': 'visible'
        })),
        state('out', style({
            'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
        })),
        transition('in => out', [group([
            animate('400ms ease-in-out', style({
                'opacity': '0'
            })),
            animate('600ms ease-in-out', style({
                'max-height': '0px'
            })),
            animate('700ms ease-in-out', style({
                'visibility': 'hidden'
            }))

        ])]),
        transition('out => in', [group([
            animate('1ms ease-in-out', style({
                'visibility': 'visible'
            })),
            animate('600ms ease-in-out', style({
                'max-height': '800px'
            })),
            animate('800ms ease-in-out', style({
                'opacity': '1'
            }))
        ]
        )])
    ])
];

export const AlertAnimation = [
    trigger('alertState', [
        state('active', style({
            'opacity': '1', 'visibility': 'visible'
        })),
        state('inactive', style({
            'max-height': '0px', 'opacity': '0', 'visibility': 'hidden', 'display': 'none'
        })),
        transition('active => inactive', [group([
            animate('400ms ease-in-out', style({
                'opacity': '0'
            })),
            animate('600ms ease-in-out', style({
                'max-height': '0px'
            })),
            animate('800ms ease-in-out', style({
                'visibility': 'hidden',
                'display': 'none'
            }))
        ])]),
        transition('inactive => active', group([
            animate('1ms ease-in-out', style({
                'visibility': 'visible'
            })),
            animate('600ms ease-in-out', style({
                'opacity': '1'
            }))
        ])),
    ])
];

export const ProgressAnimation = [
    trigger('statusChange', [
        state('inactive', style({ transform: 'translateX(200%)' })),
        state('active', style({ transform: 'translateX(-200%)'})),
        transition('active => inactive', [group([
            animate(2000)
        ])]),
        transition('inactive => active', group([
            // animate(2000)
        ])),
    ])
];

