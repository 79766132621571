export class Alert {
    typeAlert: string;
    titleAlert: string;
    messageAlert: string;
    state: string;

    constructor(){
        this.typeAlert = "success";
        this.titleAlert = "Nexus Alert";
        this.messageAlert = "";
        this.state = "active";
    }
}
