// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import config from '../assets/empresas/peru_taxi/configuraciones/config.json'
export const environment = {
    production: false,
    URL_BASE: 'https://perutaxi.nexusvirtual.net/integration_desarrollo/api/',
    //URL_BASE:'http://localhost/integration-test/api/',
    URL_PAYU: 'https://perutaxi.nexusvirtual.net/ServicioPayu/api/',
    // URL_GET_COUNTRY: 'https://ipinfo.io',
    URL_REPORTE: 'https://reporte-perutaxi.nexusvirtual.net/reporte-perutaxi/',
    URL_TRACKING: '',
    VERSION:'Versión 5.0.3',
    IDIOMA: 'es',
    versionCheckURL : 'https://perutaxi.nexusvirtual.net/intranet-dev/version.json',
    LOGO: {
      URL: 'assets/empresas/peru_taxi/img/logos/logo-empresa-menu-footer.png',
      ALT: 'logo-perutaxi'
    },
    LOGO_MENU: {
      FLAG: false,
      URL: '',
      ALT: 'logo-perutaxi'
    },
    LOGO_INICIO_SESION: {
      URL: 'assets/empresas/peru_taxi/img/logos/logo-empresa.png',
      ALT: 'logo-perutaxi'
    },
    COLOR_RUTA: "#008efb",
    COLOR: {
      PRIMARY: "#ff0000",
      SECONDARY: "#000",
      KM: "#ffffff"
    },
    ICON_BUSQUEDA: {
      ORIGEN: {
        URL: 'assets/empresas/peru_taxi/img/markers/marker-origen.png',
        ALT: 'imagen-origen'
      },
      DESTINO: {
        URL: 'assets/empresas/peru_taxi/img/markers/marker-destino.png',
        ALT: 'imagen-destino'
      },
       
      REFERENCIA: {
        URL: 'assets/empresas/peru_taxi/img/markers/referencia.png',
        ALT: 'imagen-referencia'
      },
      INSTRUCCION: {
        URL: 'assets/empresas/peru_taxi/img/markers/instruccion.png',
        ALT: 'imagen-instruccion'
      }
    },
    TEST: 'assets/empresas/peru_taxi/img/busqueda/marker-destino.png',
    MARKERS: {
      ORIGEN: {
        URL: 'assets/empresas/peru_taxi/img/markers/marker-origen.png',
        POSICION: 1,
        ICON_SIZE: new google.maps.Size(56, 56)
      },
      DESTINO: {
        URL: 'assets/empresas/peru_taxi/img/markers/marker-destino.png',
        POSICION: 2,
        ICON_SIZE: new google.maps.Size(56, 56)
      },
      TRAYECTO:{
        URL: 'assets/empresas/peru_taxi/img/markers/punto.png',
        POSICION: 3,
        ICON_SIZE: new google.maps.Size(20, 20)
      },
      CONTACTO: {
        URL: 'assets/empresas/peru_taxi/img/markers/simple_Icon.png',
        POSICION: 2,
        ICON_SIZE: new google.maps.Size(56, 56)
      },
  
      VELOCIDAD: {
        URL: 'assets/empresas/peru_taxi/img/markers/pin.png',
        POSICION: 2,
        ICON_SIZE: new google.maps.Size(56, 56)
      },
  
      CONDUCTOR: {
        URL: 'assets/empresas/peru_taxi/img/markers/conductor.png',
        POSICION: 3,
        ICON_SIZE: new google.maps.Size(56, 56)
      },
      CONDUCTOR_AUTO_LIBRE: {
        URL: 'assets/empresas/peru_taxi/img/markers/marker_verde.png',
        POSICION: 3,
        ICON_SIZE: new google.maps.Size(48, 48)
      },
      CONDUCTOR_AUTO_SINGPS: {
        URL: 'assets/empresas/peru_taxi/img/markers/marker_gris.png',
        POSICION: 3,
        ICON_SIZE: new google.maps.Size(48, 48)
      },
      
      CONDUCTOR_AUTO_OCUPADO: {
        URL: 'assets/empresas/peru_taxi/img/markers/marker_rojo.png',
        POSICION: 3,
        ICON_SIZE: new google.maps.Size(48, 48)
      },
      DRIVER: {
        URL: 'assets/empresas/peru_taxi/img/markers/ic_driver_top.png',
        POSICION: 3,
        ICON_SIZE: new google.maps.Size(56, 56)
      },
    },
    ESTADO: {
      ASIGNADO: 2,
      LEIDO: 3,
      CONTACTO: 5,
      INICIO: 6,
      TERMINADO: 7,
      LLEGO_DESTINO: 14,
      CANCELADO: 10,
      LIBRE: 1,
      PENDIENTE: 12,
      RECHAZADO: 9,
      OCUPADO:15,
      FIN_JORNADA:13,
      PREASIGNADO:16,    
      ANULADO1: 8,
      ANULADO2: 9,
      ANULADO3: 10,
      SIN_SENIAL: -1
    },
    MODO_RESERVA: {
      BASE: 1,
      APLICATIVO: 2,
      WEB_API: 3,
      WEB: 4,
      COMUNIDAD: 5
    },
    TIPO_PAGO: {
      VALE: 1,
      EFECTIVO: 2,
      ABONO: 4,
      E_VALE: 6,
      TARJETA: 7,
      CORTESIA: 20,
      TIKECK_COUNTER: 21
    },
    TIPO_SERVICIO: {
      MOTO: 10,
      POR_TIEMPO: 5
    },
    TIEMPO_CONSULTAR_RUTA: 10000,
    MENSAJES: {
      CONFIGURACION_PARAMETROS: {
        MSJ_ACTUALIZACION: 'Su información se actualizo correctamente'
      },
      SOLICITAR_SERVICIO: {
        MSJ_SERVICIO_CREADO: "Servicio creado correctamente.",
        MSJ_ERROR_DESCONOCIDO: "Ocurrio un problema, por favor intente nuevamente.",
        MSJ_SIN_PRESUPUESTO: "No cuenta con Presupuesto.",
        MSJ_VALIDACION_HORARIO: "No se puede realizar el servicio en el horario indicado.",
        MSJ_PRESUPUESTO_INACTIVO: "Presupuesto inactivo, elegir otro tipo de pago.",
        MSJ_EMPRESA_INACTIVA: "Empresa inactiva.",
        MSJ_EMPRESA_PRESUPUESTO_INACTIVO: "Empresa - Presupuesto Inactivo.",
        MSJ_CENTRO_COSTO_INACTIVO: "Centro Costo Inactivo.",
        MSJ_CENTRO_COSTO_PRESUPUESTO_INACTIVO: "Centro Costo - Presupuesto Inactivo.",
        MSJ_PRESUPUESTO_INACTIVO_CLIENTE: "Cliente - Presupuesto Inactivo.",
        MSJ_PRESUPUESTO_PLAZO_INCORRECTO: "El plazo solicitado es incorrecto, máximo 2 días de anticipación.",
        MSJ_NOTA_OBLIGATORIA: "El campo Nota 1 es obligatirio.",
        MSJ_USUARIO_NO_ENCONTRADO: "No se encontraron usuarios",
        MSJ_EMPRESA_NO_ENCONTRADA: "No se encontraron Empresas",
        MSJ_CC_NO_ENCONTRADO: "No se encontraron Centros de Costos",
        MSJ_AREAS_NO_ENCONTRADA: "No se encontraron Áreas o Subáreas", 
        MSJ_ZONAS_NO_ENCONTRADA: "No se encontraron Zonas de tiempo de reserva",
        MSJ_TRACKING_NO_ENCONTRADO: "No se encontró el tracking del conductor",
        MSJ_CUPONES_NO_ENCONTRADO: "No se encontro registro de cupones."
      },
      CANCELAR_SERVICIO: {
        MSJ_CANCELAR_SERVICIO: "Búsqueda cancelada por usuario.",
        MSJ_CANCELAR_SERVICIO_ENVIOS: "El servicio fue cancelado correctamente."
      },
      SEDES: {
        MSJ_SEDE_NO_ENCONTRADA: "No se encontraron Sedes"
      },
      LOGIN: {
        USUARIO_INACTIVO: {
          CODIGO: -201,
          DESCRIPCION: 'Usuario inactivo'
        },
        NO_EXISTE_USUARIO: {
          CODIGO: -203,
          DESCRIPCION: 'No existe usuario'
        },
        CLAVE_INCORRECTA: {
          CODIGO: -202,
          DESCRIPCION: 'Contraseña incorrecta'
        },
  
        CLAVE_NO_ESTABLECIDA: {
          CODIGO: -204,
          DESCRIPCION: 'Contraseña no establecida'
        },
        ERROR: {
          CODIGO: -1,
          DESCRIPCION: 'Ocurrio un error'
        },
        CORREO_CORPORATIVO: {
          CODIGO: 1,
          DESCRIPCION: 'Correo enviado correctamente. Por favor revise su bandeja de entrada'
        },
        RECUPERAR_CONTRASENIA: {
          CODIGO: 1,
          DESCRIPCION: 'Recibiras en unos momentos un mensaje a su correo electrónico, indicando su contraseña.'
        },
        CORREO_DUPLICADO: {
          CODIGO: -6,
          DESCRIPCION: 'Su correo ya ha sido registrado'
        },
        CORREO_SIN_ACCESOS: {
          CODIGO: -999,
          DESCRIPCION: 'Usted no tiene accesos a la web'
        }
      },
      COMPARTIR_SERVICIO: {
        MSJ_SERVICIO_NO_ENCONTRADO: "NO SE ENCONTRÓ EL SERVICIO.",
        MSJ_SERVICIO_LIBRE: "Servicio creado",
        MSJ_SERVICIO_ASIGNADO: "su conductor está en camino",
        MSJ_SERVICIO_INICIO: "está en camino",
        MSJ_SERVICIO_TERMINO: "llegó a su destino"
      },
      ENVIOS: {
        MSJ_SIN_CONDUCTOR: "No se pudo obtener coordenadas del conductor.",
        MSJ_SIN_SERVICIOS: "No se encontraron servicios",
        MSJ_CALIFICACION_ESTRELLAS: "Se calificó correctamente al conductor.",
        MSJ_SERVICIOS_POR_CALIFICAR: "Tiene servicios pendientes por calificar. Los servicios son: ",
        ERROR:{
          ERROR_CALIFICACION_ESTRELLAS: "Ocurrió un error al calificar; por favor intente mas tarde.",
        }
      },
      OCURRIO_PROBLEMA: {
        CODIGO: -1,
        DESCRIPCION: 'Ocurrio un problema, por favor intente nuevamente.'
      },
      METODO_PAGO: {
        TARJETA_CREADA: 'Se ha registrado su tarjeta correctamente',
      },
      PROMOCIONES: 'Validación exitosa',
      SERVICIOS_ENVIADOS: 'Servicios enviados correctamente',
      ZONA_PELIGROSA: 'Recojo en zona con Alta Siniestralidad por Robo reportada por las autoridades. Un operador lo llamará minutos antes del servicio para coordinar un recojo seguro.'
    },
    PROVIDER:
    {
      CULQI: 3,
      PAYU: 1
    },
    ROL:
    {
      ADMIN: 1,
      PARTICULAR: 3,
      ADMIN_CORPORATIVO: 5,
      CORPORATIVO: 6,
      ASISTENTE_CORPORATIVO: 15,
      SUPERVISOR: 13,
      OWNER: -2
    },
    SOLICITAR_MULTIPUNTO: {
      MSJ_CLEAR_RUTA: "Solicitar Servicio: No  ha ingresado los campos requeridos.",
      MSJ_LISTA_MARKERS_VACIA: "No ha ingresado el dato de destino.",
      MSJ_USUARIO_TELEFONO: "Ingrese un numero telefono.",
      MSJ_TIPO_PAGO: "Seleccione un tipo de pago.",
      MSJ_DETALLE_ENVIO: "Describa su envio.",
      MSJ_CONTACTO_NOMBRE: "Ingrese el nombre del contacto.",
      MSJ_CONTACTO_TELEFONO: "Ingrese el telefono del contacto.",
      MSJ_NO_OBTUVO_CONDUCTOR: "No se encontro conductor.",
      MSJ_MINIMO_LENGTH: "Numero de celular Incorrecto.",
      MSJ_REGISTER_TARJETA: "Su tarjeta ha sido registrada exitosamente",
      MSJ_REGISTRAR_TARJETA: "Por favor espere unos minutos mientras se registra su tarjeta",
      MSJ_TIPO_SERVICIO: "Seleccione un tipo de servicio.",
      MSJ_ID_PASAJERO: "Seleccione un personal de la empresa.",
      MSJ_TELEFONO_PASAJERO: "Ingrese un celular del personal de la empresa.",
      MSJ_LINEA_AEREA: "Ingrese la línea aérea.",
      MSJ_NRO_VUELO: "Ingrese el numero de vuelo.",
      MJ_LISTA_PERSONAL: "Espere un momento, la lista de personal se esta cargando.",
      MSJ_NO_PERSONAL: "No se encuentran resultados.",
      MSJ_NO_HA_SELECCIONADO_FECHAS: 'Seleccione fecha para el servicio de rutina',
      MSJ_NO_CENTRO_COSTO: 'No se seleccionó un Centro de Costo',
      MSJ_NO_INGRESO_CAMPOS_DINAMICOS: 'El dato de los campos dinamicos requeridos aun no ha sido ingresado',
      ERROR: {
        USUARIO_INACTIVO: 'El usuario esta inactivo',
        CONSURTAR_TARIFA: 'Ocurrio un error, Por favor intente mas tarde.',
        CREAR_SERVICIO: 'Ocurrio un error, Por favor cree un servicio nuevamente.',
        OBTENER_CONDUCTOR: 'Ocurrio un error, Por favor volver a realizar la consulta.',
        CANCELAR_SERVICIO: 'Ocurrio un error, No se pudo cancelar el servicio.',
        TIPO_PAGO: 'Ocurrio un error, Por favor recargar la pagina.',
        TIPO_PAGO_EMPTY: 'Usted no tiene ningun tipo de pago registrado.',
        TIPO_SERVICIO_EMPTY: 'Usted no tiene ningun tipo de servicio registrado.',
        LST_PERSONAL: 'Ocurrio un error, Al cargar la lista del personal.',
        LST_SEDES: 'Ocurrio un error, Al cargar la lista las Sedes',
        LST_CENTRO_COSTO_EDITABLE: 'Ocurrio un error, Al cargar los Centros de Costos.',
        SIN_PRESUPUESTO: "No cuenta con Presupuesto",
        SIN_DIRECCION: "No se encontro la dirección ingresada",
        VALIDACION_HORARIO: "No se puede realizar el servicio en el horario indicado",
        PRESUPUESTO_INACTIVO: "Presupuesto inactivo, elegir otro tipo de pago",
        EMPRESA_INACTIVA: "Empresa inactiva",
        EMPRESA_PRESUPUESTO_INACTIVO: "Empresa - Presupuesto Inactivo",
        CENTRO_COSTO_INACTIVO: "Centro Costo Inactivo",
        CENTRO_COSTO_PRESUPUESTO_INACTIVO: "Centro costo - Presupuesto Inactivo",
        PRESUPUESTO_INACTIVO_CLIENTE: "Cliente - Presupuesto Inactivo",
        SIN_TARIFA: "No se pudo obtener la tarifa para la ruta ingresada",
        SERVICIO_FECHA_RESERVA_MINIMA: "La fecha seleccionada no cumple con el tiempo minimo de reserva. ",
        PRESUPUESTO_PLAZO_INCORRECTO: "El plazo solicitado es incorrecto, máximo 2 días de anticipación",
        NOTA_OBLIGATORIA: "El campo Nota 1 es obligatorio",
        ERROR_RUTINA: "Hubo un incoveniente al crear unos servicios de rutina",
        ERROR_RUTINA_SERVICIOS: "Hubo un inconveniente al crear los servicios, con las fechas: ",
        ERROR_CONDUCTORES_CERCANOS: 'Ocurrio un error al obtener conductores cercanos, Por favor intente mas tarde.',
      }
    },
    TIPO_EMAIL: {
      NINGUNO: 0,
      NUEVO_CLIENTE: 1,
      FIN_SERVICIO: 2,
      RECUPERAR_CONTRASENIA: 4
    },
    ENVIOS: {
      HABILITAR_TRAZADO: false,
      CONSULTA_ENVIOS: 10000
    },
    MENU: {
      SOLICITAR_SERVICIO: 1,
      USUARIOS: 2,
      ENVIOS: 3,
      METODOS_PAGO: 4,
      MI_EMPRESA: 5,
      MI_CUENTA: 6,
      CERRAR_SESION: 7,
      CENTRO_COSTO: 8
    },
  
    URL_TIENDA: {
      ANDROID: 'https://play.google.com/store/apps/details?id=com.nexusvirtual.client.leveltaxi',
      IOS: 'https://itunes.apple.com/pe/app/level-taxi-cliente/id1117497500?l=en',
    },
    APPIDFB: '',
    INICIO_FB: false,
    FOTO_ESTRELLA: 'assets/estrellaConductor.png',
    API_KEY_GOOGLE: 'AIzaSyAwxmzbQwRXsn3632MYeW0i5TC1jB9pDJA',
    OPTIMIZEWAYPOINTS: false,
    SHOWPOLIGONO: false,
    MULTIDESTINO: true,
    EMPRESA: {
      PARTICULAR: 1
    },
    CENTRO_COSTO: {
      PARTICULAR: 1425
    },
    OPCIONES_DESARROLLO: true
    ,
    CODIGO_PROMOCIONAL: {
      MONEDA_LOCAL: 1,
      PORCENTAJE: 2
    },
    COUNTRY_DEV: {
      HABILITADO: false,
      PAISES: {
        BOLIVIA: {
          HABILITADO: false,
          COORD: {
            LAT: -17.787699,
            LNG: -63.185270
          },
          CODE: 'BO'
        },
        CHILE: {
          HABILITADO: false,
          COORD: {
            LAT: -33.431866,
            LNG: -70.609325
          },
          CODE: 'CL'
        },
        MEXICO: {
          HABILITADO: true,
          COORD: {
            LAT: 19.1788445,
            LNG: -96.2113356
          },
          CODE: 'MX'
        },

        COSTA_RICA: {
          HABILITADO: true,
          COORD: {
            LAT: 9.935697,
            LNG: -84.1484506
          },
          CODE: 'CR'
        },

        EC: {
          HABILITADO: false,
          COORD: {
            LAT: -2.1662649,
            LNG: -79.921618
          },
          CODE: 'EC'
        }
      }
    },
    CONF_PAIS: {
      PAISES: {
        BOLIVIA: {
          RUC: 'NIT',
          DNI: 'C.I. (Cédula de identidad)',
          REFERENCIA: 'Añada un Psj, Dpto, interior, piso, Mz Lt',
          CODE: 'BO'
        },
        CHILE: {
          RUC: 'RUT',
          REFERENCIA: 'Calle y número, Oficina / Piso, Comuna ,Ciudad',
  
          CODE: 'CL',
  
        },
        PERU: {
          RUC: 'RUC',
          DNI: 'DNI',
          REFERENCIA: 'Añada un Psj, Dpto, interior, piso, Mz Lt',
          CODE: 'PE'
  
        },
        MEXICO: {
          RUC: 'RFC',
          DNI: 'INE',
          REFERENCIA: 'Añana un Psj, Dpto, interior, piso, Mz Lt',
          CODE: 'MX'
        },
        EC:{
         
          DNI: 'C.I. (Cédula de identidad)',
          CODE: 'EC'
        },
        COSTA_RICA: {
          DNI: 'C.I. (Cédula de identidad)',
          CODE: 'CR'
        }
      }
    },
    NOMBRE_EMPRESA: 'Peru taxi',
    PAGINACION: 5,
    URL_PEAJE: 'https://perutaxi.nexusvirtual.net/fotoservicio/',
    PASARELA_PAGO: {
      CULQI: false,
      PAYU: true,
      OPENPAY: false,
      OPENPAY_MERCHANT_ID: 'momgjkw3kuo80j584dge',
      OPENPAY_PUBLIC_KEY: 'pk_07276db5c8664cfdbb9faeb9da61da05',
      OPENPAY_SANDBOX: false
    },
    COLOR_ESTADO: {
      LIBRE: '#00e676',
      ASIGNADO: '#FF9800',
      LEIDO: '#ff5252',
      CONTACTO: '#5c6bc0',
      INICIO: '#3D5afe',
      LLEGO_DESTINO: '#ef9a9a',
      PENDIENTE: '#26a69a',
      RECHAZADO: '#D50000',
      OCUPADO:'#4c535a',
      FIN_JORNADA: '#21252921',
      PREASIGNADO: '#dc561d',
      ANULADO: '#adb5df',
      SIN_SENIAL: '#e4c2e0',
    },  
    CENTER_MAP_COMPARTIR:{
      LAT:-12.1251109,
      LNG:-76.9928316
    },
  
    TARIFA_FORMA_CALCULO:{
      VALUE:3,
      TEXT:""
    },
    TEXTOS_ENVIOS: {
      PLACA: 'Económico',
      MONEDA: 'S/.',
      VEHICULO: 'Vehículo',
  
    },
    APROBACIONES: {
      MSJ_APROBADO: 'El servicio fue aprobado.',
      MSJ_RECHAZADO: 'El servicio fue rechazado.',
      MSJ_APROBADO_ANTERIORMENTE: 'El servicio fue aprobado anteriormente.',
      MSJ_RECHAZADO_ANTERIORMENTE: 'El servicio fue rechazado anteriormente.'
    },
    MONEDA: '(S/.)',
    DOCUMENTO_FISCAL:{
      NOMBRE: 'RUC',
      DESCRIPCION: 'Razon Social'
    },
    CALIFICAR: {
      ACTIVO: false
    },
    URL_FOTO_REPORTE: {
      URL: 'https://perutaxi.nexusvirtual.net/',
      VALE: 'PhotoVale/',
      PEAJE: 'PhotoPeaje/',
    },
    REGISTRO_USUARIO: true,
    TEXTO_SOLICITAR: {
      RESERVA: 'Reserva',
      INMEDIATO: 'Inmediato'
    },
    FLAG_SERVICIO_RUTINA: true,
    TIEMPO_SESION: {
      HABILITAR: true,
      TIEMPO: 120,
      PING: 60,
      TIMEOUT: 3600
    },
    COUNTRY: 'PE',
    REPORTES_PERSONALIZADO: {
      HABILITADO: false,
      NOMBRE: 'perutaxi'
    },
    CONSULTAR_CONDUCTORES: false,
    ESTADO_CONDUCTORES: {
      SINGPS: 0,
      LIBRES:1,
      OCUPADO: 2
    },
    //MOTO    
    ISMOVICAB:false,
    ICONS:{
     MOTO:false
    },
    FLAG_COUNTRY_REGISTRO: false,
    SHOW_CONTAINER_REFIN: false,
    OBSERVACION: true,
    DATOS_CONTACTO: false, 
    DATELLE_SERVICIO: true,
    SHOW_DATA_CONTACTO: false,
    DEFAULT_TIPO_PAGO_SERVICIO:true,
    FLAG_SERVICIO_RETORNO:false,
    //END MOTO
    //AEROPUERTO
    FLAG_AIRPORT:true,
    TIPO_VUELO:false,
    //END AEROPUERTO
    //TRAKING
    URL_FOTO_CONDUCTOR: '',
    URL_NOTIFICACIONES: 'https://reportes-docker.nexusvirtual.net',
    PATH_TRACKING: '',
  
    TRUNC_DECIMAL:false,
    RUTA_DEFAULT:'solicitar-servicio',
    FLAG_CLIENTE_ENCARGO: false,
    VERTRACKING: false,
    COMPANIA:true,
    VERIFICARDATOS: true,

    //PERSONALIZACION
    LABELS_PERSONALIZACION: config,

    VALIDACIONES_SOLICITAR:{    
      TRUNCAR_TOTAL_SERVICIO: false, //  solo MOVICAB true
      RETORNO_ULTIMO_DESTINO: false, // solo MOVICAB TRUE
      ULTIMO_DESTINO_TARIFAR_COMO_PRIMER_DESTINO: false, // solo MOVICAB TRUE
      INSTRUCCION_DESTINO:  false,// solo MOVICAB TRUE
      REFERENCIA_DESTINO:  false,// solo MOVICAB TRUE
      CONTACTO_ADICIONAL: false,// solo MOVICAB TRUE
      AEROPUERTO_COMBO_NACIONAL_INTER: false, //directo true
      AEROPUERTO_DETALLES_VUELO: true,
      SELECCIONAR_TIPO_PAGO_DEFECTO: true,// solo MOVICAB false
      TIPO_VEHICULO: 'AUTO', // solo mOVICAB MOTO
      SOLICITAR_SERVICIO_RUTINA: false, // solo level true
      LISTAR_CODIGO_PROMOCIONAL: false, //TODOS FALSE
      MODAL_VERIFICACION_DATOS_SERVICIO: false,// solo 24 TRUE
      OCULTARKM: false, //SOLO MOLINA TRUE    
      CELULAR_CONTACTO: true, //TODOS TRUE
      OBSERVACION: true, //SOLO NIREX FALSE
      SERVICIO_INMEDIADO: true //NIREX FALSE
    },
    VALIDACION_OWNER:{     
      DIAS_DESCUENTO : false, //solo ivancar true
      MONTO_DESCUENTO : false // solo ivancar true
    }
  };
  
  /*
   * In development mode, to ignore zone related error stack frames such as
   * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
   * import the following file, but please comment it out in production mode
   * because it will have performance impact when throw error
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  