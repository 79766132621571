import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CuentaService {

  

  constructor(private _http: HttpClient) {
   }

  getUsuario(idUsuario: any): Observable<any> {
    const url = environment.URL_BASE + 'clienteAuthorization/get?id=' + idUsuario;
    return this._http.get(url);
  }

  getCountry() {
    const url = environment.URL_BASE + 'countryAuthorization/all';
    return this._http.get(url);
  }

  postGuardarUsuario(usuario: any) {
    const url = environment.URL_BASE + 'clienteAuthorization/save';
    return this._http.post(url, usuario);
  }

  postCambiarContrasenia(usuario: any) {
    const url = environment.URL_BASE + 'clienteAuthorization/cambiarContrasenia';
    return this._http.post(url, usuario);
  }

  postGuardarFoto(json: any) {
    const url = environment.URL_BASE + 'clienteAuthorization/savePicture';
    return this._http.post(url, json);
  }

  requestObtenerSupervisor(idCliente) {
    const url = environment.URL_BASE + 'clienteAuthorization/getSupervisor?idCliente=' + idCliente;
    return this._http.get(url);
  }
}
