import { Destino } from "./destino";
import { Campos } from "./campos";
import { Multidestino } from "./multidestino";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
export class RequestServicio {
  idCliente: number;
  observacion: string;
  I007_ModoReserva: number;
  I011_TipoServicio: number;
  I008_TipoPago: number;
  Creacion_Usuario: string;
  dirOrigen: string;
  dirDestino: string;
  Pos_Destino_Latitud: number;
  Pos_Destino_Longitud: number;
  Pos_Origen_Latitud: number;
  Pos_Origen_Longitud: number;
  telefono: string;
  idEmpresa: number;
  kilometraje: number;
  Total_Servicio: number;
  anticipada: boolean;
  idCentroCosto: number;
  idToken: string;
  NumeroContacto: string;
  Pasajero: string;
  destinos: Destino[];
  aireAcondicionado: boolean;
  aire : boolean;
  clienteEncargo: string;
  varFechaServicioRetorno: Date;
  varHoraServicioRetorno:Date;
   // Destinos Uso
   lstMarkerDestino: Multidestino[];
   datosUsuario: DatosUsuario;
  //SimbolCurrency
  varSimbolCurrency: string;
  varFechaServicio: Date;
  varHoraServicio:Date;
  varTotalServicio: number;
  I056_compania: number;
  dirDestinoDistrito: string;
  DirOrigenNumero: string;
  dirOrigenDistrito: string;
  dirOrigenNumero: string;
  modificoUsuario: string;
  referencia: string;
  referenciaDestino: string;
  Usuario_Despachador: string;
  Usuario_Operador: string;
  Multidestino: boolean;
  LineaAerea: string;
  msjConductor: string;
  idPromoActivacion: string;
  Kilometros: number;
  Fecha_Servicio: string;
  creacionUsuario: string;
  I040_Visita: number;
  ProcedenciaVuelo: string;
  idPasajero: number;
  listaCampos: Campos[];
  correoPasajero: string;
  MotivoTraslado: string;
  Retorno: boolean;
  ruc: string;
  tipoVuelo: number;
  Instruccion: string;
  razonsocial: string;
  zonaOrigenPeligrosa: boolean;
  Fecha_Servicio_Retorno: string;
  listaRutina: ElementoRutina[];
  listaRetorno: ElementoRetorno[];
  NumeroVuelo: string;
  varFlagServicioRutina:boolean;
  varRetornoUltimoDestino:boolean;
  maletera: boolean;
  listaTarifa: ListaTarifa;

  constructor() {
    this.lstMarkerDestino = [new Multidestino(), new Multidestino()];
    this.datosUsuario = new DatosUsuario()
    this.aire = false;
    this.varFechaServicio = new Date();
    this.idCliente = 0;
    // this.varTotalServicio = 0;
    this.observacion = '';
    this.I007_ModoReserva = 0;
    this.I011_TipoServicio = 0;
    this.I008_TipoPago = 0;
    this.Creacion_Usuario = '';
    this.dirOrigen = '';
    this.dirDestino = '';
    this.Pos_Destino_Latitud = 0;
    this.Pos_Destino_Longitud = 0;
    this.Pos_Origen_Latitud = 0;
    this.Pos_Origen_Longitud = 0;
    this.telefono = '';
    this.idEmpresa = 0;
    this.kilometraje = 0;
    this.varFlagServicioRutina = false;
    this.anticipada = false;
    this.idCentroCosto = 0;
    this.idToken = '';
    this.NumeroContacto = '';
    this.Pasajero = '';
    this.maletera = false;
    this.aireAcondicionado = false;
    this.dirDestinoDistrito = '';
    this.DirOrigenNumero = '';
    this.dirOrigenDistrito = '';
    this.dirOrigenNumero = '';
    this.modificoUsuario = '';
    this.referencia = '';
    this.referenciaDestino = '';
    this.Usuario_Despachador = '';
    this.Usuario_Operador = '';
    this.Multidestino = false;
    this.msjConductor = '';
    this.idPromoActivacion ="";
    this.I040_Visita = 0;
    this.MotivoTraslado = "";
    this.Retorno = false;
    this.listaRutina = [];
    this.listaRetorno = [];
    this.listaTarifa = new ListaTarifa();
  }
}


export class ElementoRutina
    {
        fecha: string ;
        tarifa: number;
        varHora: Date;
        currencySimbol: string;
        fechaNb: NgbDateStruct;
        constructor(){
          this.fecha = '';
          this.tarifa = 0.0;
        }
    }
    
export class ElementoRetorno
{
    fecha: string ;
   
    constructor(){
      this.fecha = '';
   
    }
}
export class DatosUsuario {
  
  celular: string;
  clienteEncargo: string;
  centroCosto: string
  pasajero: string
  celularPasajero: string
  idPasajero: string
  clienteEncargoPasajero: string;
  emailPasajero: string;
  centroCostoPasajero: string;
  constructor() {}
}

export class  ListaTarifa{
  ISOCountryCode: string
  IsoCountryCode: string
  Kilometros: number
  abono: number
  acumDistancia: number
  currencySymbol: string
  descuento: number
  distancia: string
  idPromoActivacion: number
  idResultado: number
  isAirport: boolean
  isAirportOrigin: boolean
  lstPromociones: []
  lstTarifa: []
  monto: number
  montoSinDescuento: number
  msjTarifa: string
  overviewPolyline: string
  pagoAdelantado: boolean
  resultado: string
  totalTarifa: number
  constructor(){
    this.totalTarifa = 0;
  }
}