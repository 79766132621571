import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private currentVersion = '{{POST_BUILD_ENTERS_VERSION_HERE}}'

  constructor(private _http: HttpClient) { }

  /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
  public initVersionCheck(url, frequency = 1000 * 60 * 30) { //1000 * 60 * 30
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */

  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this._http.get(window.location.protocol + "//" + url + '?t=' + new Date().getTime())
      //.first()
      .subscribe(
        (response: any) => {

          this.currentHash = (this.currentHash == '{{POST_BUILD_ENTERS_HASH_HERE}}') ? response.hash : this.currentHash
          this.currentVersion = (this.currentVersion == '{{POST_BUILD_ENTERS_VERSION_HERE}}') ? response.version : this.currentVersion
          // const hash = response.hash;
          // const hashChanged = this.hasHashChanged(this.currentHash, hash);


          // If new version, do something
          if (this.hasHashChanged(this.currentHash, response.hash, this.currentVersion, response.version)) {
            // store the new hash so we wouldn't trigger versionChange again
            // only necessary in case you did not force refresh
            this.currentHash = response.hash;

            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
            // for an example: location.reload();
            location.reload()
          } 
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */

  private hasHashChanged(currentHash, newHash, currentVersion, newVersion) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    if (!currentVersion || currentVersion === '{{POST_BUILD_ENTERS_VERSION_HERE}}') {
      return false;
    }

    return (currentHash !== newHash || currentVersion !== newVersion);
  }
}
