import { environment } from "src/environments/environment";

export class ConfiguracionSolicitar {
    public lstCentroCosto: any[];
    public lsTipoPago: any[];
    public lstTipoServicio: any[];

    public truncarTotalServicio: boolean;
    public retornoHorario: boolean;
    public retornoUltimoDestino: boolean;
    public referenciaDestino: boolean;
    public instruccionDestino: boolean;
    public celularContacto: boolean;
    public observacion: boolean;
    public contactoAdicional: boolean;
    public emitirFactura: boolean;
    public aeropuertoOpcionNacionalInter: boolean;
    public aeropuertoDetalleVuelo: boolean;
    public seleccionarTipoPagoDefecto: boolean;
    public seleccionarTipoServicioDefecto: boolean;
    public seleccionarCentroCostoDefecto: boolean;
    public tipoVehiculo: string;
    public centroCostoEditable: boolean;
    public clienteCargo: boolean;
    public peditParaOtros: boolean;
    public pedirOtrosPersonal: boolean;
    public pedirOtrosVisita: boolean ;
    public servicioTipoRutina: boolean;
    public listaCodigoPromocional: boolean;
    public modalValidacionServicioASolicitar: boolean;
    public cobrarUltimoDestinoComoPrimerDestino: boolean;
    constructor() {
    }
}

export const tiposVechiculos = {
    MOTO: "MOTO",
    AUTO: "AUTO"
}