import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UtilModule } from 'src/app/util/util.module';
import { DefaultComponent } from './default.component';

import { MenuModule } from 'src/app/components/menu/menu.module';
import { DefaultRoutingModule } from './default-routing.module';
import { MenuComponent } from 'src/app/components/menu/menu.component';
import { PopoverModule, ModalModule, TooltipModule } from 'ngx-bootstrap';
import { IconsModule } from 'src/app/icons/icons.module';


const NgxBootstrapModule = [
  PopoverModule.forRoot(),
  ModalModule.forRoot(),
  TooltipModule.forRoot()
]

@NgModule({
  declarations: [
    DefaultComponent,
    MenuComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MenuModule,
    DefaultRoutingModule,
    NgxBootstrapModule,
    IconsModule,
    RouterModule,
    UtilModule,
   
  ],

})
export class DefaultModule { }
