export class IpCountry {
    city: string;
    country: string;
    loc: string;
    region: string;

    constructor(){

    }
}
