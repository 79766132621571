import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../clases/usuario';
import { Foto } from '../clases/foto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _http: HttpClient
   ) { }

  requestLogin(data: any) {
    //console.log(data);
    const url = environment.URL_BASE + "usuarioAuthorization/validateaccess";
    return this._http.post(url, data);  
  }

  obtenerUsuario(): Usuario {
    let usuario: Usuario = JSON.parse(localStorage.getItem('currentUser'+'_'+environment.NOMBRE_EMPRESA));
    return (usuario) ? usuario : null;
  }

  requestRegistrar(data: Usuario) {
    const url = environment.URL_BASE + "clienteAuthorization/register";
    return this._http.post(url, data);
  }

  validarCorreo(email: any) {
    const url = environment.URL_BASE + "clienteAuthorization/registerFB";
    return this._http.post(url, email);
  }

  recuperarContrasenia(email: any) {

    const url = environment.URL_BASE + "Email/recuperar";
    return this._http.post(url, email);
  }

  fotoFB(url: string, idCliente: number): Observable<any> {
    


    let picture: Foto = new Foto();
    let blob: Blob = new Blob();
    return this._http.get(url, { responseType: 'blob' }).pipe(map((res: any) => {
      //console.log(blob);
      blob = res;

      const reader = new FileReader();
      // //this.picture.foto = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObject);
      reader.readAsDataURL(blob);
      reader.onload = (e: any) => {
        picture.idCliente = idCliente//+this.usuario.IdCliente;

      
        picture.foto = reader.result.toString().split(',')[1];
        picture.foto = (<FileReader>e.target).result;
      }
      return picture;

    }));
  }

  recuperarCuenta(json:any){
    const url = environment.URL_BASE + "email/recuperar";
    return this._http.post(url, json);

  }

  actualizarClave(data: any){
    const url =  environment.URL_BASE + "usuarioAuthorization/actualizarClave"
    return this._http.post(url,data);
  }







}
