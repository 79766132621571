import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { RestriccionComponent } from "./develop/restriccion/restriccion.component";

export const routes: Routes = [
    {

        path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'default', loadChildren: () => import('./default/default/default.module').then(m => m.DefaultModule),
    },
    { path: 'reporte-aprobacion-mobile/:id/:email', loadChildren: () => import('./components/reporte-aprobacion-mobile/reporte-aprobacion-mobile.module').then(m => m.ReporteAprobacionMobileModule) },
    { path: 'compartir-servicio/:id', loadChildren: () => import('./components/compartir-servicio/compartir-servicio.module').then(m => m.CompartirServicioModule) },
    { path: 'reporte-gastos/:idEmpresa', loadChildren: () => import('./components/reporte-gastos/reporte-gastos.module').then(m => m.ReporteGastosModule) },
    { path: 'servicio-aprobado/:idServicio/:email/:ar', loadChildren: () => import('./components/servicio-aprobado/servicio-aprobado.module').then(m => m.ServicioAprobadoModule) },
    { path: 'tracking-servicio/:id/:idEmpr', loadChildren: () => import('./components/tracking-externo-servicio/tracking-externo-servicio.module').then(m => m.TrackingExternoServicioModule)},
    { path: 'compartir-tracking/:id/:movil', loadChildren: () => import('./components/compartir-tracking/compartir-tracking.module').then(m => m.CompartirTrackingModule)},
    { path: '**', component: RestriccionComponent },
];



export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
