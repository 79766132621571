
export const tokenCookieName = "auth_token"



export function setCookie(cname, cvalue, exdays?) {
    exdays = (exdays) ? exdays : 365
    var d = new Date();
    d.setTime(d.getTime() + (60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function removeCookie(cname) {
    var d = new Date();
    d.setTime(d.getTime() - (1000 * 60 * 60 * 24));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function cookieTokenAdd(tokenId: string) {
    if (getCookie(tokenCookieName) == "") {
        setCookie(tokenCookieName, tokenId)
    }
}

export function cookieTokenRemove() {
    removeCookie(tokenCookieName);
}


