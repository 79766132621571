import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restriccion',
  templateUrl: './restriccion.component.html',
  styleUrls: ['./restriccion.component.css']
})
export class RestriccionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
