import { IpCountry } from "../clases/ip-country";
import { environment } from "../../environments/environment";

export class Nexus {

    public static mostrarProgressBarIndeterminate(id: string) {
        const element = document.getElementById(id);
        let index = 0;
        const idInterval = setInterval(() => {
            if (index == 350) {
                element.style.marginLeft = "-150px";
                //element.style.width = "0px";
                index = -150;
            } else {
                //element.style.width = index + "px";
                element.style.marginLeft = index + "px";
            }
            index++;
        }, 0.5);

        return idInterval;
    }

    public static getCountryClient(service) {
        if (!environment.COUNTRY_DEV.HABILITADO) {
            if (!localStorage.getItem('country')) {
                // if(environment.FLAG_COUNTRY_REGISTRO){
                //     localStorage.setItem('country', JSON.stringify(environment.COUNTRY));
                // } else{
                    // service.requestCountry().subscribe((data: IpCountry) => {
                    //     localStorage.setItem('country', JSON.stringify(data));
                    // }, 
                    // error => {
                        // console.log(error);
                        localStorage.setItem('country', JSON.stringify(environment.COUNTRY));
                    // }
                    // );
                // }
            }
        } else {

            const objCountry: IpCountry = new IpCountry();

            if (environment.COUNTRY_DEV.PAISES.BOLIVIA.HABILITADO) {
                objCountry.country = environment.COUNTRY_DEV.PAISES.BOLIVIA.CODE;
            }

            if (environment.COUNTRY_DEV.PAISES.CHILE.HABILITADO) {
                objCountry.country = environment.COUNTRY_DEV.PAISES.CHILE.CODE;
            }

            if (environment.COUNTRY_DEV.PAISES.EC.HABILITADO) {
                objCountry.country = environment.COUNTRY_DEV.PAISES.EC.CODE;
            }

            if (environment.COUNTRY_DEV.PAISES.MEXICO.HABILITADO) {
                objCountry.country = environment.COUNTRY_DEV.PAISES.MEXICO.CODE;

            }
            
            if (environment.COUNTRY_DEV.PAISES.COSTA_RICA.HABILITADO) {
                objCountry.country = environment.COUNTRY_DEV.PAISES.COSTA_RICA.CODE;

            }

            localStorage.setItem('country', JSON.stringify(objCountry));
        }

    }

    public static geolocate() {
        if (environment.COUNTRY_DEV.HABILITADO) {
            let latLng;
            if (environment.COUNTRY_DEV.PAISES.BOLIVIA.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.BOLIVIA.COORD.LAT, environment.COUNTRY_DEV.PAISES.BOLIVIA.COORD.LNG);
            }

            if (environment.COUNTRY_DEV.PAISES.CHILE.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.CHILE.COORD.LAT, environment.COUNTRY_DEV.PAISES.CHILE.COORD.LNG);
            }

            if (environment.COUNTRY_DEV.PAISES.EC.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.EC.COORD.LAT, environment.COUNTRY_DEV.PAISES.EC.COORD.LNG);

            }
            if (environment.COUNTRY_DEV.PAISES.MEXICO.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.MEXICO.COORD.LAT, environment.COUNTRY_DEV.PAISES.MEXICO.COORD.LNG);

            }
            if (environment.COUNTRY_DEV.PAISES.COSTA_RICA.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.COSTA_RICA.COORD.LAT, environment.COUNTRY_DEV.PAISES.COSTA_RICA.COORD.LNG);

            }

            localStorage.setItem('gps', JSON.stringify(latLng))
        } else {
            if (!localStorage.getItem('gps')) {
                if (navigator.geolocation) {
                    const options: PositionOptions = {
                        enableHighAccuracy: true,
                        timeout: 20000,
                        maximumAge: 0
                    }
                    navigator.geolocation.getCurrentPosition((position: Position) => {
                        const positionLatLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                        localStorage.setItem('gps', JSON.stringify(positionLatLng))
                    },
                        (error: any) => {
                         //   console.log(error);
                        }, options);
                }
            }
        }

    }

    public static centerMapCountry() {


        let latLng = new google.maps.LatLng(environment.CENTER_MAP_COMPARTIR.LAT, environment.CENTER_MAP_COMPARTIR.LNG);
        //console.log(latLng);
        if (environment.COUNTRY_DEV.HABILITADO) {
            if (environment.COUNTRY_DEV.PAISES.BOLIVIA.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.BOLIVIA.COORD.LAT, environment.COUNTRY_DEV.PAISES.BOLIVIA.COORD.LNG);
            }

            if (environment.COUNTRY_DEV.PAISES.CHILE.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.CHILE.COORD.LAT, environment.COUNTRY_DEV.PAISES.CHILE.COORD.LNG);

            }
            if (environment.COUNTRY_DEV.PAISES.EC.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.EC.COORD.LAT, environment.COUNTRY_DEV.PAISES.EC.COORD.LNG);

            }
            if (environment.COUNTRY_DEV.PAISES.MEXICO.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.MEXICO.COORD.LAT, environment.COUNTRY_DEV.PAISES.MEXICO.COORD.LNG);

            }
            if (environment.COUNTRY_DEV.PAISES.COSTA_RICA.HABILITADO) {
                latLng = new google.maps.LatLng(environment.COUNTRY_DEV.PAISES.COSTA_RICA.COORD.LAT, environment.COUNTRY_DEV.PAISES.COSTA_RICA.COORD.LNG);

            }

        } 
        // else {
        //     if (localStorage.getItem('gps')) {
        //         latLng = JSON.parse(localStorage.getItem('gps'));
        //     } else {
        //         if (localStorage.getItem('country')) {
        //             const obj = JSON.parse(localStorage.getItem('country'));
        //             latLng = new google.maps.LatLng(obj.loc.split(',')[0], obj.loc.split(',')[1]);
        //         }
        //     }
        // }

        return latLng;
    }

    public static getCountry(address_components: google.maps.GeocoderAddressComponent[]) {
        for (let index = 0; index < address_components.length; index++) {
            const element = address_components[index];
            if (element.types[0] == "country") {
                return element.short_name;
            }
            if (element.types.length == 2) {
                if (element.types[0] == "political") {
                    return element.short_name;
                }
            }
        }
    }
    public static getCountryCode() {
        const obj = JSON.parse(localStorage.getItem('country'));
        return obj;
    }
}
