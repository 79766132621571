import { Component, OnInit, Input } from '@angular/core';
import { environment} from '../../../environments/environment'

@Component({
  selector: 'app-img-envio',
  templateUrl: './img-envio.component.html',
  styleUrls: ['./img-envio.component.css']
})
export class ImgEnvioComponent implements OnInit {
  imagen: boolean = false;

  @Input() public color ;
  constructor() { }

  ngOnInit() {
    if(environment.ICONS.MOTO == true){
      this.imagen = true;
    }else{
      this.imagen = false;
    }

  }

}
