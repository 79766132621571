import { environment } from "../../environments/environment"
import { CamposDinamicos } from "./campos-dinamicos";

export class Usuario {
  public Abono: string
  public Activo: boolean
  public Adicional: string
  public Birthday: Date
  public BirthdayInt: number
  public Campos: string
  public CargoLaboral: string
  public Celular: string
  public CentroCosto_Codigo: string
  public Clave: string
  public ClienteObs: string
  public CodigoActivacion: string
  public CodigoTrabajador: string
  public Creacion_Fecha: any
  public Creacion_Usuario: String
  public DNI: string
  public Dias: string
  public Direccion: string
  public DireccionNumero: string
  public Distrito: string
  public DocumentoIdentidad: string
  public EditCC: number
  public Email: string
  public Area: string
  public EstadoPresupuesto: boolean
  public Exigente: string
  public FlagPresupuesto: string
  public Fonema: string

  public HoraFinServicio: string
  public HoraInicioServicio: string
  public TiempoReserva: number;

  public IMEI: string
  public idAprobador: number
  public IdCentroCosto: number
  public IdCliente: number // id Cliente = null
  public IdEmpresa: number
  public IdEmpresaPrev: number
  public IdSupervisor: number
  public Latitud: string
  public LimiteServicios: number
  public Longitud: string
  public LstTarjetas: any[];
  public Materno: string
  public Modifico_Fecha: any
  public Modifico_Usuario: String
  public NombreCompleto: string
  public Nombres: string
  public OrdenInterno: string
  public Paterno: string
  public pedirTercero: boolean
  public PrepagoDisponible: number
  public PrepagoRecibido: number
  public PrepagoUsado: number
  public Presupuesto: number
  public RUC: string
  public RazonSocial: string
  public Referencia: string
  public Rol: number
  public Suscripcion: string
  public Vip: string
  public Zona: string
  public consumo: number
  public nombreEmpresa: string
  public saldo: number
  public userTokenId: string
  public isoCountryCode: string
  public prefijo: String
  public isAdmin: boolean
  public isExtUser: boolean

  public listaCentroCosto: any[];
  public listaEmpresa: any[];
  public listaPerfil: any[];
  public lstGeocerca: any[];
  public camposDinamicos: CamposDinamicos[];
  urlFoto: string = environment.URL_BASE + 'cliente/getpicture?id='
  idCodigoPromocional: number;
  public verEmpresaAprobacion: boolean
  public modoServicio: any;
  public pedirVisita: boolean;
  public pedirPersonal: boolean;
  public cambioPresupuesto: boolean;
  public emitirFactura: boolean;

  public lstCalificacion: any[];
  
  public IsoCountryCheck: boolean;

  public ComboAeropuerto: string;

  public ServicioRetorno: boolean;

  public FlagRecargo: boolean;

  public listaClienteEncargo: string[];

  HoraInicioEspecialServicio: string;
  HoraFinEspecialServicio: string;
  HoraExtendidoInicioServicio: string;
  HoraExtendidoFinServicio: string;

  public menu: any[]
  public recargoTarifa: number;
  public cerrarSesiones: boolean;
  public lstCentroCosto: number[];


  constructor() {
    this.Celular = null;
    this.idCodigoPromocional = 0
    this.Abono = ""
    this.Activo = true
    this.Adicional = ""
    this.Birthday = new Date
    this.BirthdayInt = 0
    this.Campos = null

    this.CentroCosto_Codigo = "0"
    this.ClienteObs = "0"
    this.CodigoActivacion = ""
    this.CodigoTrabajador = ""
    this.Creacion_Fecha = ""
    this.Creacion_Usuario = ""
    this.DNI = ""
    this.Dias = ""
    this.Direccion = ""
    this.DireccionNumero = ""
    this.Distrito = ""
    this.DocumentoIdentidad = ""
    this.EditCC = 0
    this.EstadoPresupuesto = true
    this.Exigente = "0"
    this.FlagPresupuesto = "0"
    this.Fonema = "0"

    this.idAprobador = 0
    this.IMEI = "0"
    this.IdCentroCosto = 0
    this.IdCliente = null// id Cliente = null
    this.IdEmpresaPrev = 0
    this.IdSupervisor = 0
    this.Latitud = "0"
    this.LimiteServicios = 0
    this.Longitud = "0"
    this.Materno = ""
    this.Modifico_Fecha = ""
    this.Modifico_Usuario = ""
    this.NombreCompleto = "0"

    this.pedirTercero = false
    this.PrepagoDisponible = 0
    this.PrepagoRecibido = 0
    this.PrepagoUsado = 0
    this.Presupuesto = 0
    this.RUC = "0"
    this.RazonSocial = "0"
    this.Referencia = "0"
    this.Rol = 0
    this.Suscripcion = "0"
    this.Vip = "0"
    this.Zona = "0"
    this.consumo = 0
    this.nombreEmpresa = "0"
    this.saldo = 0
    this.userTokenId = "0"
    this.isoCountryCode = "0"
    this.urlFoto = environment.URL_BASE + 'cliente/getpicture?id='
    this.prefijo = "+51"
    this.isAdmin = true
    this.isExtUser = false
    this.verEmpresaAprobacion = false;
    this.cambioPresupuesto = false;

    this.IsoCountryCheck = false;

    this.HoraInicioServicio = '00:00:00';
    this.HoraFinServicio = '23:59:59';
    this.HoraInicioEspecialServicio = null;
    this.HoraFinEspecialServicio = null;
    this.HoraExtendidoInicioServicio = null;
    this.HoraExtendidoFinServicio = null;
    this.TiempoReserva = 0;

    this.ComboAeropuerto = "";
    this.ServicioRetorno = false;
    this.FlagRecargo=false;
  }
}
