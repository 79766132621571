import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexusAlertComponent } from './nexus-alert/nexus-alert.component';
import { ModalCargaComponent } from './modal-carga/modal-carga.component';
import { ImgEnvioComponent } from './img-envio/img-envio.component';
import { ProgressComponent } from './progress/progress.component';
import { IconsModule } from '../icons/icons.module';


@NgModule({
  declarations: [
    NexusAlertComponent,
    ModalCargaComponent,
    ImgEnvioComponent,
    ProgressComponent,
  ],
  imports: [
    CommonModule,
    IconsModule,
  ],
  exports: [
    ModalCargaComponent,
    NexusAlertComponent,
    ImgEnvioComponent,
    ProgressComponent, 
  ]
})
export class UtilModule { }
