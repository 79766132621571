import { Component, OnInit, AfterViewInit, HostListener, OnDestroy, TemplateRef,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DefaultComponent } from '../../default/default/default.component';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { Subscription } from 'rxjs/internal/Subscription';
import { DatosCompartidosService } from '../../service/datos-compartidos.service';
import { SlideInOutAnimation } from '../../animations/animations-nexus';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Menu } from '../../clases/menu';
import { ClienteAsociado } from 'src/app/clases/clienteAsociado';
import { modalService} from '../../service/modal.service';
import * as Cookie from '../../util/cookies/cookies';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [SlideInOutAnimation]
})

export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('templatechildModal', { static: true }) templatechildModal: TemplateRef<any>;
  modalBuscar: BsModalRef;
  route: string;
  // idUsuario = '99815'; // Dario = 99815
  ADMIN_CORPORATIVO: number = environment.ROL.ADMIN_CORPORATIVO;
  ADMIN: number = environment.ROL.ADMIN;
  PARTICULAR: number = environment.ROL.PARTICULAR;
  CORPORATIVO: number = environment.ROL.CORPORATIVO;
  // imgUser = environment.URL_BASE + 'cliente/getpicture?id=' + this.idUsuario;
  usuario: string;
  dataSubscription: Subscription;
  data: any;
  state = 'out';
  isCollapsed: boolean = false;

  listaMenu: Menu[] = [];

  //Modal
  modalRef: BsModalRef;
  modalPerfil: BsModalRef;

  NOMBRE_EMPRESA = environment.NOMBRE_EMPRESA;

  //LogoEmpresa
  logo = {
    src: environment.LOGO.URL,
    alt: environment.LOGO.ALT
  }
  logoMenu = {
    src: (environment.LOGO_MENU.FLAG) ? environment.LOGO_MENU.URL : environment.LOGO.URL,
    alt: (environment.LOGO_MENU.FLAG) ? environment.LOGO_MENU.ALT : environment.LOGO.ALT
  }

  //OpcionesDesarrollo
  opcionesDesarrollo: boolean = environment.OPCIONES_DESARROLLO;

  //VersionEmpresa
  version: string = 'Version ' + environment.VERSION//environment.VERSION;

  //cliente Asociado
  lstAsociado: ClienteAsociado[] = []
  
  constructor(
    private router: Router,
    private defaultC: DefaultComponent,
    private _datosCompartidos: DatosCompartidosService,
    private modalService: BsModalService,
    private modal: modalService) {
    this.route = router.routerState.snapshot.url;

  }

  ngOnInit() {
    this.dataSubscription = this._datosCompartidos.user$
      .subscribe(data => {
        this.data = data;
        //console.log(this.data);
      });
    if (this.data.menu != undefined) {
      //this.listaMenu = this.data.menu;
      this.data.menu.forEach(elemento => {
        if (!elemento.desarrollo)
          this.listaMenu.push(elemento)
        else {
          if (this.opcionesDesarrollo) {
            this.listaMenu.push(elemento);
          }
        }
      });
    }
    this.lstAsociado = this.data.lstAsociados;
    this.usuario = this.data.NombreCompleto;
  }

  ngAfterViewInit(): void {

    this.dataSubscription = this._datosCompartidos.user$
      .subscribe(data => {
        this.data = data;
      });
      

    this.verificarOpcion();

    this.recalcularTamañoMapa();
 
  }

  verificarOpcion() {
   
    for (let index = 0; index < this.listaMenu.length; index++) {
     
      const element = this.listaMenu[index];
      const i = this.route.indexOf(element.route);
      if (i != -1) {
        this.marcarOption(this.listaMenu[index].id); 
        return;
      }
     
    }
  }
  selectOption(opcWeb: number, opcMob: number) {
    let options = document.getElementsByClassName("nav-link menu-option");
    for (let index = 0; index < options.length; index++) {
      const element = options[index];
      element.classList.remove("active");
    }
    if (options[opcWeb] != undefined)
      options[opcWeb].classList.add("active");
    if (options[opcMob] != undefined)
      options[opcMob].classList.add("active");
    //console.log(options);
   
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.isMobile();
    this.verificarOpcion();
    this.recalcularTamañoMapa();
  }

  recalcularTamañoMapa() {
    const container = document.getElementById("layout-main");
    // console.log("recalcular " + this.route);

    if (window.innerWidth <= 1024) {
      if (this.route == '/default' || this.route == '/default/solicitar-servicio') {
        if (!container.classList.contains("mapaSolicitarServicio")) {
          container.classList.add("mapaSolicitarServicio");
        }
      } else {
        container.classList.remove("mapaSolicitarServicio");
      }
    } else {
      container.classList.remove("mapaSolicitarServicio");
    }
  }
  diseñoContainerMap(){
    this.route = this.router.routerState.snapshot.url;
    const container = document.getElementById("layout-main");
    container.style.paddingLeft = "15px";

  }

  isMobile() {
    const menu = document.getElementById("menu");
    const content = document.getElementById("layout-main");
    const search = document.getElementById("search");

    if (window.innerWidth <= 1024) {
      menu.classList.remove("col-md-2");
      menu.classList.remove("sidebar");
      menu.classList.add("menu-mobile");

      content.classList.remove("col-md-10");
      content.classList.add("layout-mobile");

      if (search != undefined) {
        search.classList.add("search-container-mobile");
      }
      return true;


    } else {
      menu.classList.add("col-md-2");
      menu.classList.add("sidebar");
      menu.classList.remove("menu-mobile");

      content.classList.add("col-md-10");
      content.classList.remove("layout-mobile");

      if (search != undefined) {
        search.classList.remove("search-container-mobile");
      }

      return false;
    }
  }

  logout() {
    this.modalRef.hide();
    localStorage.removeItem('currentUser' + '_' + environment.NOMBRE_EMPRESA);
    Cookie.cookieTokenRemove()
    localStorage.removeItem('country');
    this.router.navigate(['/']);
    Cookie.removeCookie(Cookie.tokenCookieName);
  }

  public ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }
  toggleState() {
    this.state = this.state === 'out' ? 'in' : 'out'
  }

  decline(): void {
    this.modalRef.hide();
  }

  marcarOption(id: number) {
    let options = document.getElementsByClassName("nav-link");
    for (let index = 0; index < options.length; index++) {
      const element = options[index];
      element.classList.remove("active");
    }

    const marcado = document.getElementsByClassName("option-" + id);
    if (marcado != undefined) {
     
      for (let index = 0; index < marcado.length; index++) {
        const element = marcado[index];
        element.classList.add("active");
      }
       this.modalService.hide(1);
    }

  }

  cerrarSesion(template: TemplateRef<any>, id: number) {
    if (id == environment.MENU.CERRAR_SESION) {
      this.modalRef = this.modalService.show(template, { class: 'modal-confirm', backdrop: 'static' });
    } else {
      if(id != 1){
        this.diseñoContainerMap();
      }
      this.marcarOption(id)
      
      for (let index = 0; index < this.listaMenu.length; index++) {
        if(this.listaMenu[index].id==id){
            // this.modal.cargar();
            var ruta =this.listaMenu[index].route;
            this.router.navigate(['default/'+ruta])
            // this.modal.ocultarModal();    
        }
      }
    }
  }
  perfilUsuario(template: TemplateRef<any>) {
    let url: string = this.router.url;    
    // console.log(url)
    this.lstAsociado.forEach(element => {
      element.url = 'https://web.alotaxis.com/integration_test/api/cliente/getpicture?id=' + element.id_cliente
    });
    this.modalPerfil = this.modalService.show(template, { class: 'modal-confirm', backdrop: 'static' });
  }

  cambiarCuenta(asociado: ClienteAsociado) {
    this.modalPerfil.hide()
    localStorage.removeItem('currentUser' + '_' + environment.NOMBRE_EMPRESA);

    const currentUser = {
      IdCliente: asociado.id_cliente,
      Rol: asociado.rol
    }

    // console.log(currentUser)

    localStorage.setItem('currentUser' + '_' + environment.NOMBRE_EMPRESA, JSON.stringify(currentUser));

    let url: string = this.router.url;
    sessionStorage.setItem('reloadPage' + '_' + environment.NOMBRE_EMPRESA, JSON.stringify(url));
    window.location.reload();
  }
}

export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    placement: 'bottom',
    container: 'body',
    // triggers: 'focus'
  });

  
}