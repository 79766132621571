
import { NgModule } from '@angular/core';
import { SoloNumerosDirective } from './directive/solo-numeros.directive';
import { SoloLetrasDirective } from './directive/solo-letras.directive';
import { SoloNumerosPasteDirective } from './directive/solo-numeros-paste';
import { SoloNumerosEnterosDirective } from './directive/solo-numeros-enteros.directive';
import { SoloLetrasNumerosDirective } from './directive/solo-letras-numeros.directive';
import { AutocompleteLoadedDirective } from './directive/autocomplete-loaded.directive';
import { ColorEstadoPipe } from '../pipe/color-estado.pipe';



@NgModule({
  declarations: [
    SoloNumerosDirective, 
    SoloLetrasNumerosDirective, 
    SoloLetrasDirective, 
    SoloNumerosPasteDirective, 
    SoloNumerosEnterosDirective,
    AutocompleteLoadedDirective,
    ColorEstadoPipe
  ],
  exports: [
    SoloNumerosDirective, 
    SoloLetrasNumerosDirective, 
    SoloLetrasDirective, 
    SoloNumerosPasteDirective, 
    SoloNumerosEnterosDirective,
    AutocompleteLoadedDirective,
    ColorEstadoPipe
  ],    
  imports: []

})
export class sharedImputDirectiveModule { }
