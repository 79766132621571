import { Component, OnInit, ChangeDetectorRef, OnDestroy, ɵConsole } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CuentaService } from '../../service/cuenta.service';
import { Usuario } from '../../clases/usuario';
import { Subscription } from 'rxjs/internal/Subscription';
import { DatosCompartidosService } from '../../service/datos-compartidos.service';
import { UsuarioService } from '../../service/usuario.service'
import { SolicitarServicioService } from '../../service/solicitar-servicio.service';
import { Title } from '@angular/platform-browser';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
import { ConfiguracionSolicitar, tiposVechiculos } from 'src/app/clases/configuracionSolicitar';
import { actualizarConfiguracionUsuario } from 'src/app/components/solicitar-servicio/utilSolicitar';
import { modalService } from 'src/app/service/modal.service';
import { cookieTokenRemove } from 'src/app/util/cookies/cookies';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit, OnDestroy {

  fullImagePath: string;

  user: Usuario = new Usuario();
  dataSubscription: Subscription;
  data: Usuario = new Usuario;

  configuracionSolicitarSubscription: Subscription;
  configuracionSolicitar: ConfiguracionSolicitar = new ConfiguracionSolicitar();

  pais: any;
  IdCentroCosto: any;
  geocoder: google.maps.Geocoder;
  countryGoogle: string;
  loading: boolean;

  constructor(
    private titleService: Title,
    private ref: ChangeDetectorRef,
    private cuentaService: CuentaService,
    private servicioUsuario: UsuarioService,
    private _datosCompartidos: DatosCompartidosService,
    private solicitarServicioService: SolicitarServicioService,
    private userIdle: UserIdleService,
    private router: Router,
    private modalSpinner:modalService,
  ) {

    this.dataSubscription = this._datosCompartidos.user$
      .subscribe((user: any) => {
        // console.log('default')
        // console.log(user)
        this.data = user as Usuario;
        this.actualizarConfiguracionUsuario(user as Usuario);

      });

    this.configuracionSolicitarSubscription = this._datosCompartidos.configuracionSolicitar$
      .subscribe((configuracion: any) => {
        this.configuracionSolicitar = configuracion as ConfiguracionSolicitar;
      });

  }

  ngOnInit() {
    this.titleService.setTitle(environment.NOMBRE_EMPRESA);

    this.modalSpinner.ocultarModal();

    this.fullImagePath = environment.URL_BASE + 'cliente/getpicture?id=' + this.data.IdCliente;
    this.getCountryService();
    if (this.data.Rol !== environment.ROL.PARTICULAR && this.data.Rol !== environment.ROL.CORPORATIVO) {
      // this.comboCentroCosto();
      // this.comboEmpresa();
      // this.comboPerfil();
    }

    if (environment.SHOWPOLIGONO) {
      this.geocercaServicio();
    }

    if (environment.TIEMPO_SESION.HABILITAR) {
      this.userIdle.startWatching();

      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe(count => {
        //console.log(count);
      });
      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        // console.log("Fin sesion")
        localStorage.removeItem('currentUser' + '_' + environment.NOMBRE_EMPRESA);
        cookieTokenRemove()
        this.router.navigate(['/']);
      });
    }
  }

  actualizarConfiguracionUsuario(user: Usuario) {
    this.configuracionSolicitar = actualizarConfiguracionUsuario(user);
    this._datosCompartidos.updateConfiguracionSolicitar(this.configuracionSolicitar)
  }

  getFotoUsuario() {
    this.fullImagePath = environment.URL_BASE + 'cliente/getpicture?id=' + this.data.IdCliente + '&t=' + new Date().getTime();
    this.ref.detectChanges();
  }

  getUsuario(): Usuario {
    let user: Usuario = new Usuario();
    this.cuentaService.getUsuario(this.data.IdCliente).subscribe((data: any) => {
      user = data;
    });
    return user;
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.configuracionSolicitarSubscription.unsubscribe();
    this.modalSpinner.cargar();
  }

  getCountryService() {
    const paises = require('../../globals/country.json');
    this.pais = paises;
  }


  comboCentroCosto() {
    let id; // = this.data.IdEmpresa
    id = this.data.IdEmpresa;
    if (this.data.Rol == 5) {
      id = this.data.IdEmpresa;
    } else if (this.data.Rol == 1) {
      id = -1
    }


    this.servicioUsuario.centroCosto(id).subscribe((data: any) => {
      this.data.listaCentroCosto = data
      this.data.listaCentroCosto.splice(0, 0, { IdCentroCosto: -1, Codigo: 'SELECCIONE' });
    }
    )

  }

  comboEmpresa() {
    let id = this.data.IdEmpresa
    this.servicioUsuario.empresa(id).subscribe((data: any) => {
      this.data.listaEmpresa = data;
      this.data.listaEmpresa.splice(0, 0, { RazonSocial: 'SELECCIONE', IdEmpresa: -1 });

    }
    )
  }

  comboPerfil() {
    this.servicioUsuario.perfil(this.data.IdCliente).subscribe((data: any) => {
      this.data.listaPerfil = data
      this.data.listaPerfil.splice(0, 0, { Nombre_Rol: 'SELECCIONE', IdRol: -1 });

    })
  }

  geocercaServicio() {
    let lstGeocerca = [];
    let listaPoligon = [];
    this.solicitarServicioService.requestGeocerca(this.data.IdEmpresa).subscribe((data: any) => {
      for (let i = 0; i < data.list.length; i++) {
        lstGeocerca.push(data.list[i].lstGeocerca);
      }

      for (let i = 0; i < lstGeocerca.length; i++) {
        let arreglo = [];
        for (let j = 0; j < lstGeocerca[i].length; j++) {
          let json = {
            lat: parseFloat(lstGeocerca[i][j].lat),
            lng: parseFloat(lstGeocerca[i][j].lon)
          };
          arreglo.push(json);
        }
        listaPoligon.push(arreglo);
      }
    });

    this.data.lstGeocerca = listaPoligon;
  }

  accesoARuta(menu: any[], ruta): boolean {
    var acceso: boolean = false
    menu.forEach(element => {
      if (element.route === ruta) {
        acceso = true
      }
    });
    return acceso
  }
}
