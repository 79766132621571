import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { modalService} from '../../service/modal.service';
import { Modal } from '../../clases/modal';
import { BsModalRef, BsModalService} from 'ngx-bootstrap';
import { debug } from 'util';


@Component({
  selector: 'app-modal-carga',
  templateUrl: './modal-carga.component.html',
  styleUrls: ['./modal-carga.component.css']
})
export class ModalCargaComponent implements OnInit {
  @ViewChild('templatechildModal', { static: true }) templatechildModal: TemplateRef<any>;

  modal:Modal[]=[];
  modalswhith:boolean=false;
  modalBuscar: BsModalRef;
  constructor(
    private serviceModal:modalService,
    private modalCargar: BsModalService,
  ) {
    this.modal=[];
  }

  ngOnInit():void {
    this.serviceModal.getModal().subscribe(
      (modalCurrent: Modal) => {

        if(!modalCurrent){
         
          this.modal = [];
          return;
        }

        if (this.modal.length > 0) {  
          this.modal[0].state = "inactive"
         
          setTimeout(()=>{
            this.modal.shift();
          }, 500);
        }
        this.modal.push(modalCurrent);
        
        if(this.modal.length > 0){
          if(this.modal[0].state=='active'){
            this.cargar();
          }else if(this.modal[0].state=='inactive'){
            this.close();
        }
      }
      }
    );
}
  cargar(){   
    this.modalBuscar = this.modalCargar.show(this.templatechildModal,{ class: 'modal-correo', backdrop: 'static'});
}
  close(){
    this.modalCargar.hide(1);
  }
}
