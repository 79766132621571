import { Component, OnInit } from '@angular/core';
import { ProgressAnimation } from '../../animations/animations-nexus';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css'], 
  animations: ProgressAnimation
})
export class ProgressComponent implements OnInit {

  statusState: string;
  times = 999999;
  counter = 0;

  constructor() { }

  ngOnInit() {
  }

  onDone($event) {
    if (this.counter < this.times) {
      this.statusState = this.statusState === 'active' ? 'inactive' : 'active';
   
      this.counter++;
    }
  }

}
