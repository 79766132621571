export class Campos {
    e: string;
    t: any;
    c: string;
    errorData: boolean;
    requered: boolean;
    constructor(){
        this.e = '';
        this.t = '';
        this.c = '';
        this.errorData = false;
        this.requered = false;
    }
}
